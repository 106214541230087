import { cloneDeep, uniqBy } from 'lodash-es'
import moment from 'moment'
import findMostCommonOccurence from '@/lib/helpers/findMostCommonOccurence'
import { useFormatters } from '@/composables'
import { MileageItem } from '@/models'
import { VEHICLE_TYPE } from '@/enums'

export default function useMileageUtils() {
  const { formatLocale } = useFormatters()

  const setMileagePayload = (preparedMileage: any): any => {
    // TODO: other countries?
    return {
      currency: 'EUR',
      distanceUnit: 'KM',
      comment: preparedMileage.comment,
      distance: preparedMileage.distance || 0,
      sum: preparedMileage.sum || 0,
      info: {
        date: preparedMileage.date,
        vehicle: preparedMileage.vehicle,
        individualRate: preparedMileage.individualRate,
        plateNumber: preparedMileage.plateNumber,
        odometerStart: preparedMileage.odometerStart || 0,
        odometerEnd: preparedMileage.odometerEnd || 0
      }
    }
  }

  const mileageMonth = (date: string, userLang: string) => {
    if (!date) return ''
    return moment(date)
      .locale(formatLocale(userLang || 'eng'))
      .format('MMMM')
  }

  const lastMileageComments = (mileageItems: MileageItem[]) => {
    let comments: string[] = []
    // MOST RECENT MILEAGE ENTRIES HAVE HIGHER ID NUMBER
    const sortedMileage = cloneDeep(mileageItems).sort((a: MileageItem, b: MileageItem) => b.id - a.id)
    sortedMileage.forEach((mileage: MileageItem) => {
      if (mileage.comment && mileage.comment !== '' && !comments.includes(mileage.comment)) {
        comments.push(mileage.comment)
      }
    })
    return comments.slice(0, 6)
  }

  const lastVisitedPlaces = (mileageItems: MileageItem[], addressKey: 'startPointAddress' | 'endPointAddress') => {
    const coordinatesKey = addressKey === 'startPointAddress' ? 'startPoint' : 'endPoint'
    const addresses = mileageItems
      .map((item) => ({ [addressKey]: item[addressKey], [coordinatesKey]: item[coordinatesKey] }))
      .filter((address) => address[addressKey] && address[coordinatesKey])
    const mostCommon = findMostCommonOccurence(addresses.map((address) => address[addressKey]))
    const index = addresses.findIndex((address) => address[addressKey] === mostCommon)
    if (index !== -1) {
      const [mostCommonAddress] = addresses.splice(index, 1)
      addresses.unshift(mostCommonAddress)
    }
    return uniqBy(addresses, addressKey).slice(0, 6)
  }

  const calculateMileageTotal = (mileageItems: MileageItem[]) => {
    if (!mileageItems?.length) return 0
    let total = 0
    mileageItems.forEach((item) => {
      if (item.sum) {
        total = Number((total + Number(item.sum)).toFixed(2))
      }
    })
    return total
  }

  const vehicleOptions = (mileageItems: MileageItem[]) => {
    const vehicleList: Array<{ owner: string; type: string; plateNumber: string; engineCapacity: string }> = []
    mileageItems?.forEach((mileage) => {
      const { vehicle, plateNumber, engineCapacity } = mileage.info
      if (vehicle && plateNumber && engineCapacity) {
        const [owner, type] = vehicle?.split(' ') || []
        vehicleList.unshift({
          owner,
          type,
          plateNumber,
          engineCapacity
        })
      }
    })
    return uniqBy(vehicleList, 'plateNumber')
  }

  const calculateDistance = (odometerStart: string | number, odometerEnd: string | number) => {
    const distance = Number(odometerEnd) - Number(odometerStart)
    return distance < 0 ? 0 : distance
  }

  const mileageReportDateRange = (mileageItems: MileageItem[], currentMileageEntryId: string | number) => {
    if (mileageItems.length < 1) return
    let tripMileageEntries: MileageItem[] = cloneDeep(mileageItems || [])
    if (currentMileageEntryId) {
      tripMileageEntries = tripMileageEntries.filter((mileage) => mileage.id !== currentMileageEntryId)
    }
    const oneEntryDate = mileageItems[0].info?.date || ''
    const startDate = moment(oneEntryDate).startOf('month').format('YYYY-MM-DD')
    const endDate = startDate ? moment(startDate).endOf('month').format('YYYY-MM-DD') : ''
    return { startDate, endDate }
  }

  const isPrivateCar = (vehicle: string) => {
    // FOR BACKWARDS COMPATIBILITY
    return ['Private car', 'Private', VEHICLE_TYPE.PRIVATE_CAR].includes(vehicle)
  }

  const isCompanyCar = (vehicle: string) => {
    // FOR BACKWARDS COMPATIBILITY
    return ['Company car 50%', 'Company', VEHICLE_TYPE.COMPANY_CAR].includes(vehicle)
  }

  return {
    calculateMileageTotal,
    calculateDistance,
    isCompanyCar,
    isPrivateCar,
    lastMileageComments,
    lastVisitedPlaces,
    mileageReportDateRange,
    mileageMonth,
    vehicleOptions,
    setMileagePayload
  }
}
