import Axios from 'axios'
import prepareReceipts from '@/lib/preparereceipts'
import getQueryFromObj from '@/lib/helpers/getQueryFromObj'
import getRandomId from '@/lib/helpers/getRandomId'
import { massUpdate } from '@/lib/receipts'

const actions = {
  async GET_OCR(store, payload) {
    const { receiptId, companyId, timeout = 19000 } = payload

    const generatedToken = Axios.CancelToken.source()
    const requestId = getRandomId()

    store.commit(
      'Requests/SET_ACTIVE_REQUEST',
      {
        id: requestId,
        receiptId: receiptId,
        companyId: companyId,
        cancelToken: generatedToken,
        caller: 'GET_OCR'
      },
      { root: true }
    )

    const ocrURL = `@coreapi/companies/${companyId}/receipts/${receiptId}/ocr?timeout=${timeout}`

    try {
      const { data } = await Axios.get(ocrURL, {
        cancelToken: generatedToken.token
      })

      return data
    } catch (error) {
      console.log(error)
      throw error
      // TODO: handle errors
    } finally {
      store.commit(
        'Requests/REMOVE_ACTIVE_REQUEST',
        {
          id: requestId
        },
        { root: true }
      )
    }
  },
  async GET_OCRDATA(store, payload) {
    const { receiptId, companyId } = payload

    const generatedToken = Axios.CancelToken.source()
    const requestId = getRandomId()

    store.commit(
      'Requests/SET_ACTIVE_REQUEST',
      {
        id: requestId,
        receiptId: receiptId,
        companyId: companyId,
        cancelToken: generatedToken,
        caller: 'GET_OCRDATA'
      },
      { root: true }
    )

    const ocrURL = `@coreapi/companies/${companyId}/receipts/${receiptId}/ocrdata`

    try {
      const { data } = await Axios.get(ocrURL, {
        cancelToken: generatedToken.token
      })

      return data
    } catch (error) {
      throw error
      // TODO: handle errors
    } finally {
      store.commit(
        'Requests/REMOVE_ACTIVE_REQUEST',
        {
          id: requestId
        },
        { root: true }
      )
    }
  },
  async SEND_TO_OUTBOUND(store, payload) {
    const { receiptId, companyId, query = {} } = payload

    const queryPayload = getQueryFromObj(query)
    const generatedToken = Axios.CancelToken.source()
    const requestId = getRandomId()

    store.commit(
      'Requests/SET_ACTIVE_REQUEST',
      {
        id: requestId,
        receiptId: receiptId,
        companyId: companyId,
        cancelToken: generatedToken,
        caller: 'SEND_TO_OUTBOUND'
      },
      { root: true }
    )

    const targetUrl = `@api/integrations/v1/send/${companyId}/document/${receiptId}?${queryPayload}`

    try {
      const { data } = await Axios.post(targetUrl, {
        cancelToken: generatedToken.token
      })

      if ('success' in data) {
        return data
      } else {
        // old format
        return data.result
      }
    } catch (error) {
      throw error
      // TODO: handle errors
    } finally {
      store.commit(
        'Requests/REMOVE_ACTIVE_REQUEST',
        {
          id: requestId
        },
        { root: true }
      )
    }
  },
  async UPDATE_RECEIPT_DATA(store, payload) {
    const { receiptId, companyId, requestId = getRandomId(), config = {}, payload: receiptPayload = {} } = payload

    if (!companyId || !receiptId) {
      return
    }

    store.commit(
      'Requests/SET_ACTIVE_REQUEST',
      {
        id: requestId,
        receiptId: receiptId,
        companyId: companyId,
        caller: 'UPDATE_RECEIPT_DATA'
      },
      { root: true }
    )

    const { disableLocalSave = false } = config

    try {
      const { data } = await massUpdate(companyId, receiptId, receiptPayload)
      if (disableLocalSave) {
        return data
      }

      store.commit('UPDATE_LOCAL_RECEIPT', { companyId, receiptId, payload: receiptPayload })
      return data
    } catch (error) {
      throw error
      // TODO: handle errors
    } finally {
      store.commit(
        'Requests/REMOVE_ACTIVE_REQUEST',
        {
          id: requestId
        },
        { root: true }
      )
    }
  }
}

const mutations = {
  UPDATE_LOCAL_RECEIPT(state, { companyId, receiptId, payload }) {
    try {
      const newReceiptData = {
        ...this.$stash.receipts[companyId][receiptId],
        ...payload
      }

      const prepared = prepareReceipts([newReceiptData])
      const preparedReceipt = prepared[receiptId]

      if (this.$stash.receipts[companyId]?.[receiptId] && preparedReceipt) {
        this.$stash.receipts[companyId][receiptId] = {
          ...this.$stash.receipts[companyId][receiptId],
          ...preparedReceipt
        }
      }
    } catch (error) {
      throw error
    }
  }
}

export default {
  namespaced: true,
  actions,
  mutations,
  state: {},
  getters: {}
}
