import { capitalize, deburr } from 'lodash-es'
import moment from 'moment'
import { cloneDeep } from 'lodash-es'
import {
  Trip,
  CountryNames,
  ReportNameSuggestion,
  COUNTRY_CODE,
  Receipt,
  CompanyRole,
  MileageItem,
  DailyAllowanceItem
} from '@/models'
import { BILLING_TYPE, DOCUMENT_TYPE } from '@/enums'
import { useFormatters, useHelpers, useTrips, useInstanceHelpers } from '@/composables'
import specialTerritories from '@/lib/allowance/data/specialTerritories'
import countrynametranslations from '@/lib/countrynametranslations/countrynames'
import particularizeReportName from '@/lib/helpers/particularizeReportName'

export default function useTripUtils() {
  const { previousTripNames, findOverlappingPaymentTrips } = useTrips()
  const { formatLocale } = useFormatters()
  const { safeAdd } = useHelpers()
  const { t } = useInstanceHelpers()

  const documentCountTotal = (trip: Trip | undefined) => {
    let documentCount: number = 0
    if (trip?.currencyTotals && trip.currencyTotals.length) {
      trip.currencyTotals.forEach((currency: any) => {
        documentCount += currency.docCount || 0
      })
    }
    return documentCount
  }

  const calculateItemTotals = (items: any[]) => {
    if (!items?.length) return {}
    const totals: { [key: string]: any } = {}
    items.forEach((item) => {
      if (item.sum && item.currency) {
        if (!totals[item.currency]) totals[item.currency] = 0
        totals[item.currency] = Number((totals[item.currency] + Number(item.sum)).toFixed(2))
      }
    })
    return totals
  }

  const calculateReimbursementTotals = (
    receipts: Receipt[],
    mileages: MileageItem[],
    alllowances: DailyAllowanceItem[]
  ) => {
    const totals: any = {}
    receipts?.forEach(({ currency, sum, billingType, docType, extraData }) => {
      if (currency && sum && docType !== DOCUMENT_TYPE.ATTACHMENT) {
        if (!totals['total']) totals['total'] = {}
        if (!totals['total'][currency]) totals['total'][currency] = 0
        totals['total'][currency] = safeAdd(totals['total'][currency], sum)

        if (billingType === BILLING_TYPE.PERSONAL) {
          if (extraData?.emptyReceipt) {
            if (!totals['personalAdditional']) totals['personalAdditional'] = {}
            if (!totals['personalAdditional'][currency]) totals['personalAdditional'][currency] = 0
            totals['personalAdditional'][currency] = safeAdd(totals['personalAdditional'][currency], sum)
          } else {
            if (!totals['personal']) totals['personal'] = {}
            if (!totals['personal'][currency]) totals['personal'][currency] = 0
            totals['personal'][currency] = safeAdd(totals['personal'][currency], sum)
          }
          if (!totals['totalReimbursable']) totals['totalReimbursable'] = {}
          if (!totals['totalReimbursable'][currency]) totals['totalReimbursable'][currency] = 0
          totals['totalReimbursable'][currency] = safeAdd(totals['totalReimbursable'][currency], sum)
        } else if (billingType === BILLING_TYPE.COMPANY && !extraData?.emptyReceipt) {
          if (!totals['company']) totals['company'] = {}
          if (!totals['company'][currency]) totals['company'][currency] = 0
          totals['company'][currency] = safeAdd(totals['company'][currency], sum)
        } else if (!extraData?.emptyReceipt) {
          if (!totals['unspecified']) totals['unspecified'] = {}
          if (!totals['unspecified'][currency]) totals['unspecified'][currency] = 0
          totals['unspecified'][currency] = safeAdd(totals['unspecified'][currency], sum)
        }
      }
    })

    alllowances?.forEach(({ sum, currency }) => {
      if (!sum || !currency) return
      if (!totals['total']) totals['total'] = {}
      if (!totals['total'][currency]) totals['total'][currency] = 0
      totals['total'][currency] = safeAdd(totals['total'][currency], sum)

      if (!totals['totalReimbursable']) totals['totalReimbursable'] = {}
      if (!totals['totalReimbursable'][currency]) totals['totalReimbursable'][currency] = 0
      totals['totalReimbursable'][currency] = safeAdd(totals['totalReimbursable'][currency], sum)
    })

    mileages?.forEach(({ sum, currency }) => {
      if (sum && currency) {
        if (!totals['total']) totals['total'] = {}
        if (!totals['total'][currency]) totals['total'][currency] = 0
        totals['total'][currency] = safeAdd(totals['total'][currency], sum)

        if (!totals['totalReimbursable']) totals['totalReimbursable'] = {}
        if (!totals['totalReimbursable'][currency]) totals['totalReimbursable'][currency] = 0
        totals['totalReimbursable'][currency] = safeAdd(totals['totalReimbursable'][currency], sum)
      }
    })

    return totals
  }

  const createTitle = (tripDestinations: Array<any>, companyCountryCode: COUNTRY_CODE) => {
    const allCountries: CountryNames = countrynametranslations
    let countries = tripDestinations.map((d) => {
      const lang = localStorage.language || 'eng'
      // USING COMMA TO SEPARATE COUNTRY AND CITY IS BEING DEPRECATED, UNDERSCORE IS THE NEW WAY
      const destination = d.destination.includes('_') ? d.destination.split('_') : d.destination.split(',') 
      const country = destination[0]
      let city = ''
      if (destination[1]) {
        // DESTINATION HAS CITY OR OTHER TYPE OF SPECIAL TERRITORY
        //@ts-ignore
        city = specialTerritories[companyCountryCode][country][d.destination][lang]
      }
      return city || allCountries[lang][country]
    })
    let tripName = countries.join('-')
    return particularizeReportName(tripName, previousTripNames.value)
  }

  const prepSelectableMonths = (language = 'eng', monthCount: number = 3) => {
    let suggestions: Array<ReportNameSuggestion> = []
    for (let i = 0; i < monthCount; i++) {
      const suggestion: ReportNameSuggestion = {
        name: '',
        startDate: '',
        endDate: ''
      }

      const start = moment().locale(formatLocale(language)).startOf('month').subtract(i, 'month')
      suggestion.name = capitalize(start.format('MMMM, YYYY'))
      suggestion.startDate = start.format('YYYY-MM-DD')
      suggestion.endDate = start.endOf('month').format('YYYY-MM-DD')

      suggestions.unshift(suggestion)
    }
    return suggestions
  }

  const prepSelectablePaymentMonths = (localReport: Trip, language: string) => {
    // make a copy to not to edit original array
    const localReportCopy = cloneDeep(localReport)

    return prepSelectableMonths(language, 24)
      .map(({ name, startDate, endDate }) => {
        // check whether there already exists a payment report in that period
        localReportCopy.startDate = startDate
        localReportCopy.endDate = endDate
        const overLappingTrips = findOverlappingPaymentTrips(localReportCopy).length > 0

        const value = `${startDate}_${endDate}`
        return {
          title: name,
          value,
          disabled: overLappingTrips,
          extraInfo: overLappingTrips ? t('overlappingTripDates') : undefined
        }
      })
      .reverse()
  }

  const setNewTravelReportPayload = (tripDestinations: Array<any>, countryCode: COUNTRY_CODE, updatedTrip: Trip) => {
    let tripPayload = {
      type: 'travel',
      name: createTitle(tripDestinations, countryCode),
      comment: updatedTrip.comment,
      startDate: tripDestinations[0].startDate,
      endDate: tripDestinations[tripDestinations.length - 1].endDate,
      includesDailyAllowance: updatedTrip.includesDailyAllowance
    }
    return tripPayload
  }

  const filterUsers = (searchValue: string, companyRoles: CompanyRole[]) => {
    if (!companyRoles) return []

    const allRoles = companyRoles
      .filter((role) => role.userId && role.firstName && role.lastName)
      .map((role) => ({
        title: `${role.firstName} ${role.lastName}`,
        value: role.userId,
        extraInfo: role.email
      }))

    if (!searchValue) return allRoles

    const searchString = deburr(searchValue.trim()).toLowerCase()

    return allRoles.filter((option) => {
      const hasName = deburr(option.title).toLowerCase().includes(searchString)
      const hasEmail = deburr(option.extraInfo || '')
        .toLowerCase()
        .includes(searchString)
      return hasName || hasEmail
    })
  }

  const userOptions = (companyRoles: CompanyRole[]) => {
    return companyRoles
      .filter((role) => role.userId && role.firstName && role.lastName)
      .map((role) => ({
        title: `${role.firstName} ${role.lastName}`,
        value: role.userId,
        extraInfo: role.email || ''
      }))
  }

  return {
    calculateItemTotals,
    calculateReimbursementTotals,
    createTitle,
    documentCountTotal,
    filterUsers,
    prepSelectableMonths,
    setNewTravelReportPayload,
    userOptions,
    prepSelectablePaymentMonths
  }
}
