export default {
  GB: {
    'GB_London': {
      eng: 'London',
      est: 'London',
      fin: 'Lontoo',
      lav: 'Londona',
      rus: 'Лондон',
      pol: 'Londyn',
      swe: 'London',
      lit: 'Londonas',
      esp: 'Londres'
    },
    'GB_Edinburgh': {
      eng: 'Edinburgh',
      est: 'Edinburgh',
      fin: 'Edinburgh',
      lav: 'Edinburga',
      rus: 'Эдинбург',
      pol: 'Edynburg',
      swe: 'Edinburgh',
      lit: 'Edinburgas',
      esp: 'Edimburgo'
    }
  },
  US: {
    'US_New York': {
      eng: 'New York',
      est: 'New York',
      fin: 'New York',
      lav: 'Ņujorka',
      rus: 'Нью-Йорк',
      pol: 'Nowy Jork',
      swe: 'New York',
      lit: 'Niujorkas',
      esp: 'Nueva York'
    },
    'US_Los Angeles': {
      eng: 'Los Angeles',
      est: 'Los Angeles',
      fin: 'Los Angeles',
      lav: 'Losandželosa',
      rus: 'Лос-Анджелес',
      pol: 'Los Angeles',
      swe: 'Los Angeles',
      lit: 'Los Andželas',
      esp: 'Los Angeles'
    },
    'US_Washington': {
      eng: 'Washington',
      est: 'Washington',
      fin: 'Washington',
      lav: 'Vašingtona',
      rus: 'Вашингтон',
      pol: 'Waszyngton',
      swe: 'Washington',
      lit: 'Vašingtonas',
      esp: 'Washington'
    },
    'US_Virgin Islands': {
      eng: 'Virgin Islands',
      est: 'Neitsisaared',
      fin: 'Neitsytsaaret',
      lav: 'Virdžīnas',
      rus: 'Виргинские острова',
      pol: 'Wyspy Dziewicze',
      swe: 'Jungfruöarna',
      lit: 'Mergelių salos',
      esp: 'Islas Vírgenes'
    }
  },
  RU: {
    'RU_Moscow': {
      eng: 'Moscow',
      est: 'Moskva',
      fin: 'Moskova',
      lav: 'Maskava',
      rus: 'Москва',
      pol: 'Moskwa',
      swe: 'Moskva',
      lit: 'Maskva',
      esp: 'Moscú'
    },
    'RU_Saint Petersburg': {
      eng: 'Saint Petersburg',
      est: 'Peterburi',
      fin: 'Pietari',
      lav: 'Sanktpēterburga',
      rus: 'Санкт-Петербург',
      pol: 'Petersburg',
      swe: 'Sankt Petersburg',
      lit: 'Sankt Petersburgas',
      esp: 'San Petersburgo'
    }
  },
  TR: {
    'TR_Istanbul': {
      eng: 'Istanbul',
      est: 'İstanbul',
      fin: 'Istanbul',
      lav: 'Stambula',
      rus: 'Стамбул',
      pol: 'Stambuł',
      swe: 'Istanbul',
      lit: 'Stambulas',
      esp: 'Estanbul'
    }
  },
  PT: {
    'PT_Madeira': {
      eng: 'Madeira',
      est: 'Madeira',
      fin: 'Madeira',
      lav: 'Madeira',
      rus: 'Мадейра',
      pol: 'Madera',
      swe: 'Madeira',
      lit: 'Madeira',
      esp: 'Madeira'
    },
    'PT_Azores': {
      eng: 'Azores',
      est: 'Assoorid',
      fin: 'Azorit',
      lav: 'Azoru salas',
      rus: 'Азорские острова',
      pol: 'Azory',
      swe: 'Azorerna',
      lit: 'Azorai',
      esp: 'Azores'
    }
  },
  ES: {
    'ES_Canary Islands': {
      eng: 'Canary Islands',
      est: 'Kanaari saared',
      fin: 'Kanariansaaret',
      lav: 'Kanāriju Salas',
      rus: 'Канарские острова',
      pol: 'Wyspy Kanaryjskie',
      swe: 'Kanarieöarna',
      lit: 'Kanarų salos',
      esp: 'Canarias'
    }
  }
}