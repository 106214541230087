import { computed } from 'vue'
import moment from 'moment'
import xstore from '@/store'
import { useContext, useReceipts } from '@/composables'
import { MILEAGE_STORE_ACTIONS, MILEAGE_STORE_GETTERS, DOCUMENT_SUBTYPE } from '@/enums'
import { Mileage, MileageItem, Receipt } from '@/models'
import Axios from 'axios'

export default function useMileage() {
  const { root, store } = useContext()
  const { activeTripReceipts } = useReceipts()

  const companyId = computed(() => Number(root?.$route?.params?.companyId || 0))
  const tripId = computed(() => root?.$route?.params?.tripId || '')

  const mileage = computed<Mileage>(() => {
    return xstore.getters[MILEAGE_STORE_GETTERS.MILEAGE][companyId.value]
  })

  const tripMileage = computed<MileageItem[]>(() => {
    return Object.values(mileage.value || {})
      .filter((mileageItem) => {
        return mileageItem.tripId === Number(tripId.value)
      })
      .sort((mileageA, mileageB) => {
        return new Date(mileageB.info?.date).getTime() - new Date(mileageA.info?.date).getTime()
      })
  })

  const tripCarRegCards = computed<Receipt[]>(() => {
    return activeTripReceipts.value.filter((receipt) => receipt.docSubType === DOCUMENT_SUBTYPE.CAR_REGISTRATION_CARD)
  })

  const mileageById = (id: string | number) => {
    return tripMileage.value.find((item: MileageItem) => Number(item.id) === Number(id))
  }

  const mileageByPlateNumber = (plateNumber: string) => {
    return Object.values(mileage.value).filter((mileage) => {
      return mileage?.info?.plateNumber === plateNumber
    })
  }

  const getMileage = async () => {
    if (!companyId.value) return
    return await xstore.dispatch(MILEAGE_STORE_ACTIONS.GET_MILEAGE, {
      companyId: companyId.value
    })
  }

  const getTripMileage = async () => {
    if (!companyId.value || !tripId.value) return []
    return await xstore.dispatch(MILEAGE_STORE_ACTIONS.GET_TRIP_MILEAGE, {
      companyId: companyId.value,
      tripId: tripId.value
    })
  }

  const getUserMileagePerMonth = async (
    startDate: string,
    endDate: string,
    userId: number | string,
    isAdmin: Boolean
  ) => {
    if (!companyId.value || !userId || !startDate || !endDate) return []
    if (isAdmin) {
      let url = `@coreapi/companies/${companyId.value}/trips/mileages?userId=${userId}`
      if (startDate && endDate) {
        url = `${url}&from=${startDate}&to=${endDate}`
      }
      return Axios.get(url).then(({ data: mileages = [] }) => mileages)
    }
    return await Axios.get(`@coreapi/companies/${companyId.value}/trips/mileage`).then(({ data: mileages = [] }) =>
      mileages.filter((mileage: MileageItem) => {
        return (
          mileage.info?.date && moment(mileage.info.date).isBetween(moment(startDate), moment(endDate), undefined, '[]')
        )
      })
    )
  }

  const addMileage = async (mileageItem: MileageItem): Promise<MileageItem | undefined> => {
    if (!companyId.value || !tripId.value) return
    return await xstore.dispatch(MILEAGE_STORE_ACTIONS.ADD_MILEAGE_ITEM, {
      companyId: companyId.value,
      tripId: tripId.value,
      mileageItem
    })
  }

  const updateMileage = async (
    mileageId: string | number,
    mileageItem: MileageItem
  ): Promise<MileageItem | undefined> => {
    if (!companyId.value || !tripId.value) return
    return await xstore.dispatch(MILEAGE_STORE_ACTIONS.UPDATE_MILEAGE_ITEM, {
      companyId: companyId.value,
      tripId: tripId.value,
      mileageId,
      mileageItem
    })
  }

  const deleteMileage = async (mileageId: string | number): Promise<boolean> => {
    if (!companyId.value || !tripId.value) return false
    return await xstore.dispatch(MILEAGE_STORE_ACTIONS.DELETE_MILEAGE_ITEM, {
      companyId: companyId.value,
      tripId: tripId.value,
      mileageId
    })
  }

  return {
    mileage,
    tripMileage,
    tripCarRegCards,
    addMileage,
    deleteMileage,
    getMileage,
    getTripMileage,
    getUserMileagePerMonth,
    mileageById,
    mileageByPlateNumber,
    updateMileage
  }
}
