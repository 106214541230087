import { computed, reactive, ref } from 'vue'
import Axios from 'axios'
import { xstore } from '@/store'
import { validateStatus } from '@/lib/status'
import {
  useContext,
  useUser,
  useExpenseFlow,
  useCompanies,
  useReceipts,
  useLoadingState,
  useHelpers,
  useInstanceHelpers,
  useTrips,
  useCardReconciliation
} from '@/composables'
import {
  RECEIPTS_STORE_ACTIONS,
  RECEIPTS_STORE_MUTATIONS,
  RECEIPT_STATUS,
  TRIP_TYPE,
  PRICING_PLAN,
  BILLING_TYPE,
  DOCUMENT_TYPE
} from '@/enums'
import { Receipt } from '@/models'

export default function useFileUpload() {
  const { root } = useContext()
  const { digitizeReceipt, submitReceipt } = useExpenseFlow()
  const { activeUser } = useUser()
  const { activeCompany } = useCompanies()
  const { activeTrip } = useTrips()
  const { activeTripStatement } = useCardReconciliation()
  const { receiptById } = useReceipts()
  const { setLoadingState } = useLoadingState()
  const { findFileExtension } = useHelpers()
  const { t } = useInstanceHelpers()

  const addedReceiptCount = ref(0)

  const tripId = computed(() => root?.$route.params.tripId)
  const companyId = computed(() => root.$route.params.companyId?.toString())

  const receiptsThisMonthCount = computed(() => {
    return (Number(activeCompany.value?.receiptsThisMonthCount) || 0) + addedReceiptCount.value
  })

  const monthlyLimitExceeded = computed(() => {
    return receiptsThisMonthCount.value > 4 && activeCompany.value?.planId === PRICING_PLAN.FREE_DEMO
  })

  const files: any = reactive({
    list: {},
    createdReceipts: []
  })

  const supportedFileFormats = [
    'application/pdf',
    'image/jpeg',
    'image/png',
    'image/bmp',
    'image/tiff',
    'image/heif',
    'image/heic',
    'image/heic-sequence',
    'image/webp',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  ]

  const checkDocumentLimit = async () => {
    if (activeCompany.value?.planId !== PRICING_PLAN.FREE_DEMO) return false

    if (monthlyLimitExceeded.value) return true

    if (receiptsThisMonthCount.value > 4) return true
  }

  const addFiles = async (filesInput: any, digitize: boolean, uploadType = '') => {
    const fileList = [...filesInput]
    const keys = Object.keys(files.list)

    // Take previous biggest tempIndex to assign next ones
    let nextTempId = Number(keys[keys.length - 1] || 0) + 1

    if (fileList.length) {
      fileList.forEach((file, index) => {
        const fileInfo: any = {}
        fileInfo.imgPreviewUrl = URL.createObjectURL(file)
        fileInfo.tempId = nextTempId
        fileInfo.name = file.name
        fileInfo.extension = findFileExtension(file.name)
        if (!supportedFileFormats.includes(file.type)) {
          fileInfo.isNotSupported = true
        }

        fileInfo.uploadPercentage = 0
        files.list[nextTempId] = fileInfo
        fileList[index].tempId = nextTempId

        nextTempId += 1
      })

      for (const file of fileList) {
        const fileInfo = files.list[file.tempId]
        if (!fileInfo.isNotSupported) {
          await addNewReceipt(file, fileInfo, uploadType, digitize)
        }
      }
    }
    return true
  }

  const updateUploadProgress = (fileTempId: number, uploadProgressPercentage: number) => {
    files.list[fileTempId].uploadPercentage = uploadProgressPercentage
  }

  const createReceipt = async (initialPayload = {}) => {
    try {
      let newReceiptPayload: any = {
        tripId: Number(tripId.value) || null
      }

      if (activeTrip.value?.subtype === TRIP_TYPE.CREDIT_CARD || activeTrip.value?.subtype === TRIP_TYPE.DEBIT_CARD) {
        newReceiptPayload.billingType = BILLING_TYPE.COMPANY
        newReceiptPayload.paymentMethodId =
          activeTrip.value?.paymentCard?.id ||
          activeTripStatement.value?.paymentMethodId ||
          activeTrip.value?.extraData?.paymentMethodId // THIS HAS BEEN DEPRECATED, HERE FOR BACKWARDS COMPAT
      }

      if (activeTrip.value?.userId && activeUser.value?.id !== activeTrip.value?.userId) {
        newReceiptPayload.userId = activeTrip.value.userId
      }

      if (initialPayload) {
        Object.assign(newReceiptPayload, initialPayload)
      }

      const receipt = await xstore.dispatch(RECEIPTS_STORE_ACTIONS.CREATE_RECEIPT, {
        companyId: companyId.value,
        values: newReceiptPayload
      })
      return receipt
    } catch (error) {
      console.log('ERROR CREATING RECEIPT', error)
      throw error
    }
  }

  const uploadReceipt = async (receipt: Receipt, fileToUpload: any) => {
    try {
      const formData = new FormData()
      formData.append('file', fileToUpload)
      const uploadUrl = `@coreapi/companies/${companyId.value}/receipts/${receipt.id}/image`
      const fileUploaded = await Axios.post(uploadUrl, formData)
      xstore.commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPTS, [{ ...receipt, status: 1 }])
      return fileUploaded
    } catch (error) {
      console.log('ERROR UPLOADING FILE', error)
      throw error
    }
  }

  const receiveOcr = async (receipt: Receipt) => {
    let timeout = activeCompany.value?.dPreferences?.itemRowsDigitization ? 39000 : 19000
    try {
      const ocrData = await xstore.dispatch(RECEIPTS_STORE_ACTIONS.GET_OCR, {
        companyId: companyId.value,
        receipt,
        timeout
      })

      return ocrData
    } catch (error) {
      // IF FAILED FOR THE FIRST TIME TRY AGAIN
      timeout = 100000
      try {
        const ocrData = await xstore.dispatch(RECEIPTS_STORE_ACTIONS.GET_OCR, {
          companyId: companyId.value,
          receipt,
          timeout
        })
        return ocrData
      } catch (error) {
        console.log('ERROR RECEIVING OCR', error)
        throw error
      }
    }
  }

  const addNewReceipt = async (fileToUpload: any, fileInfo: any, uploadType: string, digitize = false) => {
    try {
      const limitExceeded = await checkDocumentLimit()
      if (limitExceeded) {
        updateUploadProgress(fileInfo.tempId, -1)
        return
      }

      const initialPayload: any = {}
      if (uploadType === 'attachment') {
        initialPayload.docType = DOCUMENT_TYPE.ATTACHMENT
      }

      const receiptCreated = await createReceipt(initialPayload)
      updateUploadProgress(fileInfo.tempId, 10)

      const fileUploaded = await uploadReceipt(receiptCreated, fileToUpload)
      updateUploadProgress(fileInfo.tempId, 80)

      files.createdReceipts.push(receiptCreated.id)
      files.list[fileInfo.tempId].receiptId = receiptCreated.id

      addedReceiptCount.value += 1

      if (uploadType !== 'attachment') {
        const ocrReceived = await receiveOcr(receiptCreated)
      }

      // SHOULD BE USED ONLY IN CLOUD FOR PREVENTING AUTOMATED ACCOUNTING 
      // OVERRIDING ACCOUNTING REFS MANUALLY ENTERED BY USER
      if (digitize && activeCompany.value?.digitization !== true) {
        await digitizeReceipt(activeCompany.value, receiptCreated)
      }

      updateUploadProgress(fileInfo.tempId, 100)

      return true
    } catch (error: any) {
      updateUploadProgress(fileInfo.tempId, -1)
      if (error.response?.data?.message) {
        fileInfo.errored = true
        files.list[fileInfo.tempId].errorMessage = error.response.data.message
      }
    }
  }

  const addCarRegCard = async (event: any, payload: any) => {
    try {
      const cardFile = event.target.files[0]
      const receiptCreated = await createReceipt(payload)
      await uploadReceipt(receiptCreated, cardFile)
    } catch (error) {
      throw error
    }
  }

  const removeFromList = (fileReceiptId: number | undefined, index: number | undefined) => {
    if (!fileReceiptId || index === undefined) return
    delete files.list[index]
    files.createdReceipts = files.createdReceipts.filter((receiptId: number) => receiptId !== fileReceiptId)
  }

  const submitUploadedReceipts = async () => {
    setLoadingState(true)

    for (const receiptId of files.createdReceipts) {
      try {
        if (!isUploadedDocSubmitted(receiptId)) {
          const createdReceipt = receiptById(receiptId)
          if (createdReceipt.id && activeCompany.value?.id) {
            await submitReceipt(activeCompany.value, createdReceipt)
          }
        }
        const submittedFile: any = Object.values(files.list).find((file: any) => file.receiptId === receiptId)
        if (submittedFile.tempId !== undefined) {
          files.list[submittedFile.tempId].submitted = true
        }
      } catch (error) {
        const erroredFile: any = Object.values(files.list).find((file: any) => file.receiptId === receiptId)
        if (erroredFile?.tempId !== undefined) {
          files.list[erroredFile.tempId].errored = true
          files.list[erroredFile.tempId].errorMessage = `${t('unsubmitted')}: ${t(root?.eh(error))}`
        }
        console.log('ERROR SUBMITTING ADDED DOCS', error)
      }
    }

    setLoadingState(false)
    return files.createdReceipts
  }

  const isUploadedDocSubmitted = (receiptId: number | string | undefined): Boolean => {
    if (!receiptId) return false
    const createdReceipt = receiptById(receiptId)
    return Boolean(createdReceipt.status && validateStatus(createdReceipt.status, [RECEIPT_STATUS.SUBMITTED]))
  }

  return {
    files,
    monthlyLimitExceeded,
    addFiles,
    addNewReceipt,
    addCarRegCard,
    removeFromList,
    submitUploadedReceipts,
    isUploadedDocSubmitted,
    checkDocumentLimit
  }
}
