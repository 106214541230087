import { getCurrentInstance, computed, inject } from 'vue'
import { routerLocationKey } from '@/providers'
import { xstore } from '@/store'

export default function useContext () {
  const instance = getCurrentInstance()
  const root = instance?.proxy!
  const router = inject(routerLocationKey)!
  const store = xstore

  const route = computed(() => {
    return router?.currentRoute.value
  })

  return {
    router,
    route,
    store,
    root
  }
}