module.exports = {
  AF: 61,
  AL: 89,
  DZ: 82,
  AD: 65,
  AO: 69,
  AR: 45,
  AM: 59,
  AW: 69,
  AU: 77,
  AT: 82,
  BS: 89,
  BH: 78,
  BD: 56,
  BB: 79,
  BY: 50,
  BE: 79,
  BZ: 52,
  BJ: 47,
  BM: 89,
  BT: 37,
  BO: 48,
  BW: 42,
  BR: 70,
  BG: 66,
  BF: 41,
  BI: 55,
  KH: 66,
  CM: 62,
  CA: 80,
  CV: 45,
  CF: 102,
  TD: 52,
  CL: 55,
  CN: 73,
  CO: 61,
  KM: 46,
  CK: 73,
  CR: 64,
  HR: 71,
  CU: 64,
  CW: 57,
  CY: 66,
  CZ: 89,
  DK: 80,
  DJ: 83,
  DM: 60,
  DO: 50,
  EC: 62,
  EG: 51,
  SV: 58,
  ER: 97,
  EE: 78,
  ET: 46,
  FO: 62,
  FJ: 54,
  FR: 79,
  GA: 95,
  GM: 44,
  GE: 46,
  DE: 77,
  GH: 41,
  GR: 70,
  GL: 63,
  GD: 72,
  GP: 54,
  GT: 77,
  GN: 85,
  GW: 45,
  GY: 51,
  HT: 79,
  HN: 58,
  HU: 69,
  IS: 93,
  IN: 61,
  ID: 56,
  IR: 119,
  IQ: 71,
  IE: 79,
  IL: 89,
  IT: 77,
  JM: 61,
  JP: 69,
  KZ: 60,
  KE: 80,
  XK: 61,
  KW: 84,
  LV: 73,
  LB: 95,
  LS: 37,
  LR: 59,
  LY: 52,
  LI: 81,
  LT: 72,
  LU: 79,
  MG: 46,
  MW: 61,
  MY: 54,
  MV: 66,
  ML: 49,
  MT: 72,
  MH: 67,
  MQ: 57,
  MR: 49,
  MU: 49,
  MX: 73,
  FM: 60,
  MD: 76,
  MC: 92,
  MN: 44,
  ME: 68,
  MA: 72,
  MZ: 52,
  NA: 39,
  NP: 51,
  NL: 86,
  NZ: 77,
  NI: 50,
  NE: 56,
  NG: 68,
  NO: 70,
  OM: 72,
  PK: 33,
  PW: 107,
  PA: 60,
  PG: 67,
  PY: 34,
  PE: 50,
  PH: 70,
  PL: 81,
  PT: 72,
  PR: 71,
  QA: 75,
  RO: 71,
  RW: 33,
  KN: 65,
  LC: 83,
  WS: 61,
  SM: 60,
  ST: 119,
  SA: 78,
  SN: 57,
  RS: 78,
  SC: 81,
  SL: 58,
  SG: 82,
  SK: 81,
  SI: 73,
  SB: 62,
  SO: 88,
  ZA: 54,
  SS: 119,
  ES: 75,
  LK: 31,
  SD: 119,
  SR: 104,
  SE: 68,
  CH: 96,
  TW: 67,
  TZ: 49,
  TH: 65,
  TG: 63,
  TO: 65,
  TT: 80,
  TN: 65,
  TR: 41,
  TM: 86,
  UG: 49,
  UA: 57,
  AE: 72,
  GB: 83,
  US: 88,
  UY: 55,
  UZ: 33,
  VU: 72,
  VE: 119,
  VN: 69,
  YE: 97,
  ZW: 119,
  ZM: 46,
  AG: 95,
  AZ: 69,
  PT: 70,
  BA: 62,
  BN: 45,
  ES: 73,
  HK: 83,
  CG: 66,
  CD: 58,
  CI: 83,
  TL: 47,
  SZ: 40,
  JO: 88,
  KP: 67,
  KR: 85,
  KG: 43,
  LA: 34,
  MM: 71,
  MK: 66,
  PS: 89,
  RU: 73,
  VC: 85,
  SY: 78,
  TJ: 35,
  TR: 42,
  GB: 87,
  VI: 63,
  US: 95,
  'PT, Azores': 70,
  'ES, Canary Islands': 73,
  'PT, Madeira': 69,
  'RU, Moscow': 91,
  'RU, Saint Petersburg': 83,
  'TR, Istanbul': 42,
  'GB, London': 87,
  'GB, Edinburgh': 87,
  'US, New York': 95,
  'US, Los Angeles': 95,
  'US, Washington': 95,
  'US, Virgin Islands': 63
}
