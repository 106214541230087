<template>
  <div class="select-wrapper" :class="{ desktop }">
    <div class="select-container" v-if="showButton">
      <!-- SELECT BUTTON -->
      <div class="select-button-wrapper">
        <!-- BUTTON -->
        <button
          class="select-button"
          :class="{
            active: localState.isPopoverShown,
            invalid: !isValid,
            'option-selected': localState.selectedOptions.length,
            'extra-space': floatingLabel
          }"
          @click="togglePopover()"
          :disabled="isDisabled"
        >
          <p class="button-text ellipsis" v-if="!floatingLabel || (floatingLabel && localState.selectedOptions.length)">
            <!-- PLACEHOLDER -->
            <span v-if="inlineLabel && localState.selectedOptions.length" class="inline-label">{{ `${label}:` }}</span>

            <!-- SELECTED VALUE -->
            <span>{{ localState.buttonText }}</span>
          </p>
          <span v-else></span>

          <!-- DROP ICON -->
          <div class="icon-wrapper">
            <icon-chevron-down class="select-arrow"></icon-chevron-down>
          </div>
        </button>

        <!-- FLOATING PLACEHOLDER -->
        <span v-if="floatingLabel" class="floating-label ellipsis">{{ label }}</span>
      </div>
    </div>

    <!-- SELECT OVERLAY -->
    <Transition>
      <div class="select-overlay" v-if="localState.isPopoverShown" @click="togglePopover(false)">
        <!-- CLOSE ICON -->
        <div class="disp-flex just-end">
          <icon-close class="m-t-16 m-r-16" fill="#ffffff" height="30px" width="30px"></icon-close>
        </div>

        <!-- SELECT OPTIONS PANEL -->
        <div class="select-popover" v-if="localState.isPopoverShown">
          <div class="select-label-wrapper" v-if="label">
            <p class="paragraph-sm-regular purple-text">{{ label }}</p>
          </div>

          <!-- SEARCH -->
          <div class="search-wrapper" v-if="searchable && options?.length" @click.stop="() => false">
            <!-- INPUT -->
            <input
              ref="search"
              v-model="localState.searchWord"
              :placeholder="t('search')"
              @input="onSearchInput()"
              @keydown.enter="onSearchEnter()"
              v-sanitize
            />

            <!-- AUTOCOMPLETE VALUE -->
            <div v-if="localState.searchAutocomplete" class="autocomplete">
              <p>{{ localState.searchAutocomplete.title }}</p>
            </div>

            <!-- CLEAR SEARCH INPUT ICON -->
            <button class="icon-wrapper" v-if="localState.searchWord.length" @click="clearSearchInput()">
              <icon-cancel class="no-shrink" fill="#484e70" height="24px" width="24px"></icon-cancel>
            </button>

            <!-- SEARCH ICON -->
            <div v-else class="icon-wrapper">
              <icon-search class="no-shrink" fill="#969bb0" height="24px" width="24px"></icon-search>
            </div>
          </div>

          <!-- OPTIONS -->
          <template v-for="(option, index) in localState.options" :key="index">
            <div
              v-if="option.value !== undefined && option.title !== undefined"
              class="select-option"
              @click="() => onOptionClick(option.value, option.title)"
              :class="{
                'select-option--is-selected': localState.selectedOptions.some((item) => item.value === option.value),
                'select-option--is-disabled': option.disabled,
                'select-option--is-highlighted': option.highlighted,
                'select-option--is-button': option.isButton
              }"
            >
              <slot name="option" :option="option">
                <!-- OPTION TITLE -->
                <p>{{ option.title }}</p>

                <!-- SECONDARY INFO -->
                <p class="extra-info" v-if="option.extraInfo">{{ option.extraInfo }}</p>
              </slot>
            </div>
          </template>

          <!-- EMPTY LIST -->
          <div v-if="!options?.length" class="select-option select-option--is-disabled">
            {{ t('listIsEmpty') }}
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
import { onMounted, onBeforeUnmount, reactive, ref, watch } from 'vue'
import { deburr, debounce } from 'lodash-es'

export default {
  name: 'UiSelect',
  emits: ['update:modelValue', 'close'],
  props: {
    modelValue: {
      type: [String, Number, Array, Boolean]
    },
    label: {
      type: String,
      default: null
    },
    options: {
      type: Array,
      required: true
    },
    isMultipleChoice: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    floatingLabel: {
      type: Boolean,
      default: false
    },
    inlineLabel: {
      type: Boolean,
      default: false
    },
    canUnselect: {
      type: Boolean,
      default: true
    },
    autoOpen: {
      type: Boolean,
      default: false
    },
    isValid: {
      type: Boolean,
      default: true
    },
    desktop: {
      type: Boolean,
      default: false
    },
    searchable: {
      type: Boolean,
      default: false
    },
    showButton: {
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    const ENTER_KEY_CODE = 13
    const DOWN_ARROW_KEY_CODE = 40
    const UP_ARROW_KEY_CODE = 38
    const ESCAPE_ARROW_KEY_CODE = 27
    let onButtonClick = false

    const search = ref(null)

    const localState = reactive({
      options: [],
      selectedOptions: [],
      buttonText: props.label,
      isPopoverShown: false,
      searchWord: '',
      searchAutocomplete: null
    })

    onMounted(() => {
      window.addEventListener('keydown', preventDefaultForKeys)
      window.addEventListener('keyup', onKeyup)
      setLocalOptions()
      setSelected()
      if (props.autoOpen) {
        localState.isPopoverShown = true
      }
    })

    onBeforeUnmount(() => {
      window.removeEventListener('keydown', preventDefaultForKeys)
      window.removeEventListener('keyup', onKeyup)
    })

    watch(
      () => props.modelValue,
      () => {
        if (onButtonClick) {
          onButtonClick = false
          return
        }
        setSelected()
      }
    )

    watch(
      () => localState.isPopoverShown,
      () => {
        localState.searchAutocomplete = null
      }
    )

    watch(
      () => props.options,
      () => {
        setLocalOptions()
        setSelected()
      }
    )

    const preventDefaultForKeys = (event) => {
      if (localState.isPopoverShown) {
        // Prevent scrolling
        if ([ENTER_KEY_CODE, DOWN_ARROW_KEY_CODE, UP_ARROW_KEY_CODE, ESCAPE_ARROW_KEY_CODE].includes(event.keyCode)) {
          event.preventDefault()
        }
      }
    }

    const onKeyup = (event) => {
      if (localState.isPopoverShown) {
        let currentActiveElementIndex = localState.options?.findIndex((option) => option.highlighted)

        if (event.keyCode === UP_ARROW_KEY_CODE || event.keyCode === DOWN_ARROW_KEY_CODE) {
          for (let i = 1; i < localState.options?.length; i++) {
            let nextActiveElementIndex

            // Select next option
            if (currentActiveElementIndex === -1) {
              nextActiveElementIndex = 0
            } else {
              nextActiveElementIndex =
                event.keyCode === UP_ARROW_KEY_CODE ? currentActiveElementIndex - i : currentActiveElementIndex + i
            }

            // Go from first to last and last to first option
            nextActiveElementIndex = (nextActiveElementIndex + localState.options.length) % localState.options.length

            // Make sure option is not disabled
            if (!localState.options[nextActiveElementIndex]?.disabled) {
              if (localState.options[currentActiveElementIndex]?.highlighted) {
                localState.options[currentActiveElementIndex].highlighted = false
              }
              localState.options[nextActiveElementIndex].highlighted = true
              break
            }
          }
        } else if (event.keyCode === ESCAPE_ARROW_KEY_CODE) {
          togglePopover(false)
        } else if (event.keyCode === ENTER_KEY_CODE && currentActiveElementIndex !== -1) {
          const option = localState.options[currentActiveElementIndex]
          onOptionClick(option.value, option.title)
        }
      }
    }

    const setLocalOptions = () => {
      localState.options = props.options.filter((option) => {
        return option?.value !== undefined && option?.title !== undefined
      })
    }

    const filterOptionsOnSearch = () => {
      if (!localState.searchWord.length) {
        localState.options = props.options
        return
      }
      const modifiedSearchWord = deburr(localState.searchWord.trim() || '').toLowerCase()
      localState.options = props.options.filter((option) => {
        const foundTitle = deburr(option.title || '')
          .toLowerCase()
          .includes(modifiedSearchWord)
        const foundExtraInfo = deburr(option.extraInfo || '')
          .toLowerCase()
          .includes(modifiedSearchWord)
        return foundTitle || foundExtraInfo
      })
    }

    function setSelected() {
      if (props.isMultipleChoice && Array.isArray(props.modelValue) && props.modelValue.length > 0) {
        localState.selectedOptions = props.options.filter((option) => props.modelValue.includes(option.value))
        localState.selectedOptions = localState.selectedOptions.filter(
          (selectedOption) => selectedOption && selectedOption.value && selectedOption.title
        )
      } else if (props.modelValue) {
        let selectedOption = props.options?.find((option) => option.value === props.modelValue)
        localState.selectedOptions = []
        if (selectedOption && selectedOption.value && selectedOption.title) {
          localState.selectedOptions.push(selectedOption)
        }
      } else if (!props.modelValue) {
        localState.selectedOptions = []
      }
      setButtonText()
    }

    function togglePopover(shouldShow = true) {
      localState.isPopoverShown = shouldShow
      if (shouldShow && props.searchable) {
        setTimeout(() => {
          search.value.focus()
        }, 500)
      }
      if (!shouldShow) {
        clearSearchInput()
        emit('close')
        setLocalOptions()
      }
    }

    function onOptionClick(value, title) {
      onButtonClick = true
      if (props.isMultipleChoice) {
        const selectedValues = setMultipleValues(value, title)
        emit('update:modelValue', selectedValues)
      } else {
        const selectedValue = setSingleValue(value, title)
        emit('update:modelValue', selectedValue)
        togglePopover(false)
      }
      setButtonText()
    }

    function setButtonText() {
      if (localState.selectedOptions.length < 1) {
        localState.buttonText = props.label
        return
      }
      if (localState.selectedOptions.length === 1) {
        localState.buttonText = localState.selectedOptions[0].title
        return
      }
      localState.buttonText = `${localState.selectedOptions[0].title}, ...`
    }

    function setSingleValue(value, title) {
      if (localState.selectedOptions.length > 0 && localState.selectedOptions[0].value === value && props.canUnselect) {
        localState.selectedOptions = []
        return ''
      }
      localState.selectedOptions = [{ value, title }]
      return value
    }

    function setMultipleValues(value, title) {
      if (localState.selectedOptions.some((item) => item.value === value)) {
        localState.selectedOptions = localState.selectedOptions.filter((item) => item.value !== value)
      } else {
        localState.selectedOptions.push({ value, title })
      }
      return localState.selectedOptions.map((item) => item.value)
    }

    const setAutocompleteValue = debounce(() => {
      if (localState.options?.length && localState.searchWord.length) {
        const foundMatch = localState.options.find((option) => {
          return option.title.startsWith(localState.searchWord)
        })
        localState.searchAutocomplete = foundMatch
      }
    }, 300)

    function clearSearchInput() {
      localState.searchWord = ''
      localState.options = props.options
      localState.searchAutocomplete = null
    }

    function onSearchInput() {
      if (!props.searchable) return
      filterOptionsOnSearch()
      localState.searchAutocomplete = null
      setAutocompleteValue()
    }

    function onSearchEnter() {
      if (localState.searchAutocomplete?.value) {
        const { value, title } = localState.searchAutocomplete
        onOptionClick(value, title)
      }
    }

    return {
      localState,
      search,
      togglePopover,
      onOptionClick,
      onSearchInput,
      onSearchEnter,
      clearSearchInput
    }
  }
}
</script>

<style lang="scss" scoped>
.select-wrapper {
  width: 100%;
}

.select-button-wrapper {
  position: relative;
  width: 100%;
  min-width: 0;
}

.select-overlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $transparent-purple;
  z-index: $modal-layer;
}

.select-button {
  @extend %box-outline;
  @extend %box-text;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 56px;
  height: auto;
  width: 100%;
  padding: 15px 16px;
  font-weight: 400;
  text-align: left;
  color: $grey;
  background: $white;
  cursor: pointer;
  transition: border 150ms ease;

  .icon-wrapper {
    height: 24px;
    padding-left: 8px;
  }

  .select-arrow {
    fill: $medium-dark-grey;
    width: 24px;
    height: 24px;
    transition: transform 150ms;
  }

  &.extra-space {
    padding: 8px 16px;
  }

  &.option-selected {
    color: $medium-dark-grey;
    font-weight: 400;
  }

  &:enabled:hover,
  &:enabled:focus {
    border: 1.5px solid $grey;
  }

  &:disabled {
    background: $light-grey;
    cursor: default;
    color: $grey;

    .select-arrow {
      fill: $grey;
    }
  }

  &.invalid {
    border: 2px solid $main-red !important;
  }

  &.active {
    border: 2px solid $main-blue !important;
    color: $medium-dark-grey;

    .select-arrow {
      transform: rotate(180deg);
    }
  }
}

.button-text {
  align-self: flex-end;
}

.floating-label {
  position: absolute;
  width: 70%;
  font-weight: 400;
  left: 17px;
  top: 16px;
  color: $grey;
  pointer-events: none;
  transition: all 100ms ease;
}

.option-selected + .floating-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  transform: translateY(-8px);
}

.inline-label {
  display: inline-block;
  margin-right: 4px;
  font-weight: 600;
  color: $grey;
}

.select-popover {
  @extend %box-outline;
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 88%;
  max-width: 568px;
  max-height: 70vh;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 8px 0;
  background: $white;
  overflow: auto;
  @include scrollbar;
}

.select-label-wrapper {
  padding: 4px 24px 12px;
  text-align: center;
}

.search-wrapper {
  position: relative;
  display: flex;
  padding: 16px;
  width: 100%;
  background: $white;

  input {
    position: relative;
    @extend %box-text;
    border: none;
    width: 100%;
    caret-color: $main-blue;
    color: $medium-dark-grey;
    background: transparent;
    z-index: 2;

    &::placeholder {
      color: $grey;
    }

    &::-ms-input-placeholder {
      color: $grey;
    }
  }

  .autocomplete {
    @extend %box-text;
    position: absolute;
    top: 18px;
    left: 16px;
    font-weight: 400;
    color: $grey;
    z-index: 1;
  }
}

.select-option {
  padding: 16px;
  min-height: 48px;
  width: 100%;
  line-height: 16px;
  font-weight: 400;
  background: $white;
  cursor: pointer;
  flex-shrink: 0;

  &:hover {
    background: $light-grey;
  }

  &--is-selected {
    background: $light-blue;
  }

  &--is-disabled {
    color: $grey;
    pointer-events: none;
    cursor: default;
  }

  &--is-button {
    font-weight: 500;
    color: $main-blue;
  }

  &--is-highlighted {
    background: $medium-light-grey;
  }

  .extra-info {
    font-size: 12px;
    color: $grey;
  }
}

.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

// DESKTOP VIEW
.desktop {
  .select-button {
    min-height: 40px;
    padding: 8px 12px;
    font-size: 12px;
  }

  .select-arrow {
    height: 18px;
    width: 18px;
  }

  .icon-wrapper {
    height: 18px;
  }
}
</style>
