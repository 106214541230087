import Axios from 'axios'
import { format, addDays } from 'date-fns'
import queryCreator from '@/lib/queryCreator'
import {
  RECEIPTS_STORE_MUTATIONS,
  RECEIPTS_STORE_ACTIONS,
  RECEIPTS_STORE_GETTERS,
  TRIPS_STORE_ACTIONS,
  CONFIG_STORE_GETTERS,
  SETTING_STORE_ACTIONS,
  TRIPS_STORE_MUTATIONS
} from '@/enums'
import { useTrips } from '@/composables'
import { Receipt } from '@/models'

export default {
  [RECEIPTS_STORE_ACTIONS.GET_FILTERED_RECEIPTS]: async ({ commit }: any, { params, config }: any) => {
    try {
      const {
        selectBy = 'createdAt',
        startDate = null,
        endDate = null,
        companyId = null,
        tripId = null,
        userId = null,
        status = null,
        orderBy = 'status',
        offset = 0,
        limit = null,
        submitter = true
      } = params

      if (!companyId) {
        return []
      }

      const dateEndPlusOneDay = format(addDays(new Date(endDate), 1), 'yyyy-MM-dd')

      const receiptsQueryCreator = queryCreator()
        .where(selectBy, ({ isBetween }: any) =>
          startDate && dateEndPlusOneDay ? isBetween(startDate, dateEndPlusOneDay) : ''
        )
        .where('tripId', ({ isEqualTo }: any) => isEqualTo(tripId))
        .includeSubmitter(submitter)
        .includeItemRows(true)
      // .where('userId', ({ isEqualTo }: any) => !!userId && isEqualTo(userId))
      // .where('status', ({ isBetween }: any) => !!status && isBetween(status, status * 2))

      const receiptsQuery = queryCreator()
        .clone(receiptsQueryCreator)
        .orderBy(orderBy)
        // .offset(offset)
        // .limit(limit)
        .base(`@api/core/v1/companies/${companyId}/receipts`)
        .get()

      const { data: receipts = [] } = await Axios.get(receiptsQuery)

      // RECEIPTS COME ORDERED BY STATUS BUT WE NEED THE RECEIPTS WITH ERRORED STATUSES FIRST
      const reversedReceipts = receipts.reverse()

      commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPTS, reversedReceipts)
    } catch (error) {
      console.error(RECEIPTS_STORE_ACTIONS.GET_FILTERED_RECEIPTS, error)
    }
  },
  [RECEIPTS_STORE_ACTIONS.GET_RECEIPT]: async ({ commit }: any, payload: any = {}) => {
    try {
      const { companyId, receiptId } = payload

      if (!companyId || !receiptId) return

      const { data = {} } = await Axios.get(
        `@coreapi/companies/${companyId}/receipts/${receiptId}?submitter=true&itemrows=true`
      )

      commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPTS, [data])

      return data
    } catch (error) {
      console.error(RECEIPTS_STORE_ACTIONS.GET_RECEIPT, error)
    }
  },
  [RECEIPTS_STORE_ACTIONS.GET_RECEIPT_DUPLICATES]: async ({ commit }: any, payload: any = {}) => {
    try {
      const { companyId, receiptId } = payload

      if (!companyId || !receiptId) return

      const { data = [] } = await Axios.get(`@api/core/v1/companies/${companyId}/receipts/${receiptId}/duplicates`)

      const duplicates = data.filter((duplicate: Receipt) => duplicate.id !== Number(receiptId))

      commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_DUPLICATES, { receiptId, duplicates })

      return duplicates
    } catch (error) {
      console.error(RECEIPTS_STORE_ACTIONS.GET_RECEIPT, error)
      throw error
    }
  },
  [RECEIPTS_STORE_ACTIONS.MARK_AS_NON_DUPLICATE]: async ({ commit }: any, payload: any = {}) => {
    try {
      const { companyId, receiptId } = payload

      if (!companyId || !receiptId) return

      return Axios.post(`@api/core/v1/companies/${companyId}/receipts/${receiptId}/nonduplicate`)
    } catch (error) {
      console.error(RECEIPTS_STORE_ACTIONS.GET_RECEIPT, error)
      throw error
    }
  },
  [RECEIPTS_STORE_ACTIONS.UPDATE_RECEIPT_DATA]: async ({ commit, getters, dispatch }: any, payload: any = {}) => {
    try {
      const {
        disableUpdatingParentTrip = false,
        disablePopUp = false,
        requestPayload = {},
        companyId = null,
        receiptId = null,
        values = {}
      } = payload

      const { data } = await Axios.put(
        `@api/core/v1/companies/${companyId}/receipts/${receiptId}`,
        values,
        requestPayload
      )

      const currentReceiptState = getters[RECEIPTS_STORE_GETTERS.RECEIPT_BY_ID](receiptId)

      // RECEIPT TRIP CHANGED?
      if (values.tripId && currentReceiptState?.tripId !== values.tripId) {
        // UPDATE PREVIOUS TRIP
        if (currentReceiptState.tripId) {
          dispatch(
            TRIPS_STORE_ACTIONS.GET_TRIP,
            {
              companyId,
              tripId: currentReceiptState.tripId
            },
            { root: true }
          )
        }

        // UPDATE NEXT TRIP
        if (values.tripId && !disableUpdatingParentTrip) {
          dispatch(
            TRIPS_STORE_ACTIONS.GET_TRIP,
            {
              companyId,
              tripId: values.tripId
            },
            { root: true }
          )
        }
      }
      commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_VALUES, { receipt: currentReceiptState, values: { ...data } })

      return data
    } catch (error) {
      console.error(RECEIPTS_STORE_ACTIONS.UPDATE_RECEIPT_DATA, error)
      throw error
    }
  },
  [RECEIPTS_STORE_ACTIONS.DIGITIZE_RECEIPT]: async ({ commit }: any, payload: any = {}) => {
    try {
      const { company, receipt } = payload
      const { data = {} } = await Axios.put(`@coreapi/companies/${company.id}/receipts/${receipt.id}/digitized`)

      commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_VALUES, { receipt, values: { status: data.status } })
      return data
    } catch (error) {
      throw error
    }
  },
  [RECEIPTS_STORE_ACTIONS.SUBMIT_RECEIPT]: async ({ commit }: any, payload: any = {}) => {
    try {
      const { company, receipt, suppressDuplicateCheck } = payload

      let url = `@coreapi/companies/${company.id}/receipts/${receipt.id}/submit`

      if (suppressDuplicateCheck) {
        url = `${url}?suppressDuplicateCheck=true`
      }

      const { data = {} } = await Axios.post(url)

      commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_VALUES, { receipt, values: { status: data.status } })
      return data
    } catch (error) {
      throw error
    }
  },
  [RECEIPTS_STORE_ACTIONS.UNSUBMIT_RECEIPT]: async ({ commit }: any, payload: any = {}) => {
    try {
      const { companyId, receipt } = payload
      const { data = {} } = await Axios.post(`@coreapi/companies/${companyId}/receipts/${receipt.id}/unsubmit`)

      commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_VALUES, { receipt, values: { status: data.status } })
      return data
    } catch (error) {
      throw error
    }
  },
  [RECEIPTS_STORE_ACTIONS.RESUBMIT_RECEIPT]: async ({ commit }: any, payload: any = {}) => {
    try {
      const { company, receipt, suppressDuplicateCheck } = payload

      let url = `@coreapi/companies/${company.id}/receipts/${receipt.id}/resubmit`

      if (suppressDuplicateCheck) {
        url = `${url}?suppressDuplicateCheck=true`
      }

      const { data = {} } = await Axios.post(url)

      commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_VALUES, { receipt, values: { status: data.status } })
      return data
    } catch (error) {
      throw error
    }
  },
  [RECEIPTS_STORE_ACTIONS.CONFIRM_RECEIPT]: async ({ commit }: any, payload: any = {}) => {
    try {
      const { company, receipt } = payload
      const { data = {} } = await Axios.post(`@coreapi/companies/${company.id}/receipts/${receipt.id}/ready`)

      commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_VALUES, { receipt, values: { status: data.status } })
      return data
    } catch (error) {
      throw error
    }
  },
  [RECEIPTS_STORE_ACTIONS.SEND_TO_OUTBOUND]: async ({ commit }: any, payload: any = {}) => {
    try {
      const { company, receipt } = payload
      const { data = {} } = await Axios.post(
        `@api/integrations/v1/send/${company.id}/document/${receipt.id}?prevalidation=true`
      )

      let result
      if ('success' in data) {
        result = data
      } else {
        result = data.result
      }

      if (result?.receipt?.status) {
        commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_VALUES, { receipt, values: { status: result.receipt.status } })
      } else if (result?.status) {
        commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_VALUES, { receipt, values: { status: result.status } })
      }
      return result
    } catch (error) {
      throw error
    }
  },
  [RECEIPTS_STORE_ACTIONS.DELETE_RECEIPT]: async ({ commit, dispatch }: any, payload: any = {}) => {
    try {
      const { company, receipt } = payload

      dispatch(SETTING_STORE_ACTIONS.UPDATE_LOADING_STATE, true)

      const { data } = await Axios.delete(`@coreapi/companies/${company.id}/receipts/${receipt.id}`)

      if (data?.efData?.trip?.id) {
        const { tripById } = useTrips()
        const trip = tripById(data?.efData?.trip?.id)
        commit(TRIPS_STORE_MUTATIONS.SET_TRIP_VALUES, { trip, values: { status: data?.efData?.trip?.status } })
      }

      commit(RECEIPTS_STORE_MUTATIONS.REMOVE_RECEIPT, receipt.id)
    } catch (error) {
      throw error
    } finally {
      dispatch(SETTING_STORE_ACTIONS.UPDATE_LOADING_STATE, false)
    }
  },
  [RECEIPTS_STORE_ACTIONS.REMOVE_RECEIPT]: async ({ commit }: any, payload: any = {}) => {
    const { receiptId } = payload
    commit(RECEIPTS_STORE_MUTATIONS.REMOVE_RECEIPT, receiptId)
  },
  [RECEIPTS_STORE_ACTIONS.DELETE_RECEIPTS_MASS]: async ({ commit, dispatch }: any, payload: any = {}) => {
    try {
      // TODO: investigate, why report is loaded so many times after receipts are deleted
      const { company, receiptIds = [] } = payload

      for (const receiptId of receiptIds) {
        await Axios.delete(`@coreapi/companies/${company.id}/receipts/${receiptId}`).then(({ data = {} }) => {
          commit(RECEIPTS_STORE_MUTATIONS.REMOVE_RECEIPT, receiptId)
          return data
        })
      }

      return true
    } catch (error) {
      throw error
    }
  },
  [RECEIPTS_STORE_ACTIONS.DOWNLOAD_RECEIPT_PDF]: async ({}, payload: any = {}) => {
    const { companyId, receiptId, fileName } = payload
    const url = `@coreapi/companies/${companyId}/receipts/${receiptId}/file/pdf`
    Axios.get(url, { responseType: 'blob' }).then((response) => {
      const a = document.createElement('a')
      a.href = window.URL.createObjectURL(response.data)
      a.download = `${fileName}.pdf`
      document.body.appendChild(a)
      a.click()
      a.remove()
    })
  },
  [RECEIPTS_STORE_ACTIONS.EXPORT_PERIODIC]: async ({ getters }: any, payload: any = {}) => {
    try {
      const { docType, companyId, from, to, options } = payload

      if (!docType || !companyId || !from || !to) {
        return
      }

      const type = encodeURIComponent(docType)
      const { services } = getters[CONFIG_STORE_GETTERS.CONFIG]
      const token = localStorage.token
      const lang = localStorage.language

      let url = `${services.api.url}/export/v1/${companyId}/report/periodic?access_token=${token}&from=${from}&to=${to}&mediatype=${type}&lang=${lang}`

      Object.keys(options).forEach((option) => {
        url = `${url}&${option}=${options[option]}`
      })

      window.open(url, '_blank')
    } catch (error) {
      throw error
    }
  },
  [RECEIPTS_STORE_ACTIONS.DOWNLOAD_RECEIPTS]: async ({ getters }: any, payload: any = {}) => {
    try {
      const { companyId, receiptIds } = payload

      if (!companyId || !receiptIds?.length) return

      const { services } = getters[CONFIG_STORE_GETTERS.CONFIG]
      const ids = receiptIds.join(',')
      const token = localStorage.token
      const url = `${services.api.url}/export/v1/${companyId}/documents/files?access_token=${token}&mediatype=application/zip&ids=${ids}`
      window.open(url, '_blank')
    } catch (error) {
      throw error
    }
  },
  [RECEIPTS_STORE_ACTIONS.DOWNLOAD_IMPORT_SOURCE_FILE]: async ({ getters }: any, payload: any = {}) => {
    try {
      const { companyId, receiptId } = payload

      if (!companyId || !receiptId) return

      const { services } = getters[CONFIG_STORE_GETTERS.CONFIG]
      const token = localStorage.token
      const url = `${services.api.url}/core/v1/companies/${companyId}/receipts/${receiptId}/import-source-file?access_token=${token}`
      window.open(url, '_blank')
    } catch (error) {
      throw error
    }
  },
  [RECEIPTS_STORE_ACTIONS.EXPORT_RECEIPTS]: async ({ getters }: any, payload: any = {}) => {
    try {
      const { companyId, receiptIds, docType, options = {} } = payload

      if (!companyId || !receiptIds?.length || !docType) return

      const { services } = getters[CONFIG_STORE_GETTERS.CONFIG]
      const type = encodeURIComponent(docType)
      const ids = receiptIds.join(',')
      const token = localStorage.token
      const lang = localStorage.language
      let url = `${services.api.url}/export/v1/${companyId}/documents/query?access_token=${token}&mediatype=${type}&ids=${ids}&lang=${lang}&metadataLang=${lang}`

      Object.keys(options).forEach((option) => {
        url = `${url}&${option}=true`
      })

      // if (addImageUrl) {
      //   url = `${url}&addInvoiceImageUrl=true`
      // }

      // if (markSent) {
      //   url = `${url}&markSent=true`
      // }

      return window.open(url, '_blank')
    } catch (error) {
      throw error
    }
  },
  [RECEIPTS_STORE_ACTIONS.CREATE_RECEIPT]: async ({ commit }: any, payload: any = {}) => {
    let { companyId, values } = payload

    if (!companyId) return

    try {
      const { data = {} } = await Axios.post(`@api/core/v1/companies/${companyId}/receipts`, values)

      commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPTS, [data])

      return data
    } catch (error) {
      throw error
    }
  },
  [RECEIPTS_STORE_ACTIONS.GET_OCR]: async ({ commit }: any, payload: any) => {
    try {
      const { receipt, companyId, timeout = 19000, onlyForRectangles = false } = payload

      if (!receipt?.id || !companyId) return {}

      const generatedToken = Axios.CancelToken.source()

      const url = `@coreapi/companies/${companyId}/receipts/${receipt?.id}/ocr?timeout=${timeout}`

      const { data = {} } = await Axios.get(url, {
        cancelToken: generatedToken.token
      })

      if (onlyForRectangles && data.supplier && !data.status && !data.dFlags && !data.originalName) {
        // TODO in backend:
        // Special case where OCR has failed, and now doc should be digitised again.
        // Example: OCR server was down while email was sent
        throw 'pleaseDigitiseAgain'
      } else {
        commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_VALUES, { receipt, values: { ocr: data.ocr } })
      }

      return data
    } catch (error) {
      console.log(error)
      throw error
    }
  },
  [RECEIPTS_STORE_ACTIONS.UPDATE_RECEIPT_BROWSING_LIST]: async ({ commit }: any, payload: any = {}) => {
    let { receiptIds } = payload

    commit(RECEIPTS_STORE_MUTATIONS.SET_RECEIPT_BROWSING_LIST, { receiptIds })
  }
}
