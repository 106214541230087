import { COST_TYPES_STORE_MUTATIONS, COST_TYPES_STORE_STATE } from '@/enums'
import { ReceiptsStoreState, ExpenseType } from '@/models'

export default {
  [COST_TYPES_STORE_MUTATIONS.SET_EXPENSE_TYPES]: (state: ReceiptsStoreState, expenseTypes: ExpenseType[]) => {
    if (!Array.isArray(expenseTypes)) return
    state[COST_TYPES_STORE_STATE.EXPENSE_TYPES] = expenseTypes
  },
  [COST_TYPES_STORE_MUTATIONS.SET_EXPENSE_TYPE]: (state: ReceiptsStoreState, updatedExpenseType: ExpenseType) => {
    if (updatedExpenseType.parentId) {
      const parentExpenseType = (state[COST_TYPES_STORE_STATE.EXPENSE_TYPES] || []).find((existingExpenseType) => {
        return existingExpenseType.id === updatedExpenseType.parentId
      })
      const existingSubTypeIndex = (parentExpenseType?.subgroup || []).findIndex(
        (subType) => subType.id === updatedExpenseType.id
      )
      if (parentExpenseType?.subgroup && existingSubTypeIndex > -1) {
        parentExpenseType.subgroup[existingSubTypeIndex] = {
          ...parentExpenseType.subgroup[existingSubTypeIndex],
          ...updatedExpenseType
        }
      } else if (parentExpenseType?.subgroup) {
        parentExpenseType.subgroup.push(updatedExpenseType)
      } else if (parentExpenseType) {
        parentExpenseType.subgroup = [updatedExpenseType]
      }
      const expenseTypes = (state[COST_TYPES_STORE_STATE.EXPENSE_TYPES] || []).map((existingExpenseType) => {
        return existingExpenseType.id === parentExpenseType?.id ? parentExpenseType : existingExpenseType
      })
      state[COST_TYPES_STORE_STATE.EXPENSE_TYPES] = expenseTypes
    } else {
      const expenseTypes = (state[COST_TYPES_STORE_STATE.EXPENSE_TYPES] || []).map((existingExpenseType) => {
        return Number(existingExpenseType.id) === Number(updatedExpenseType.id)
          ? {
              ...existingExpenseType,
              ...updatedExpenseType
            }
          : existingExpenseType
      })
      state[COST_TYPES_STORE_STATE.EXPENSE_TYPES] = expenseTypes
    }
  },
  [COST_TYPES_STORE_MUTATIONS.DELETE_EXPENSE_TYPE]: (state: ReceiptsStoreState, expenseTypeId: string | number) => {
    const expenseTypes = (state[COST_TYPES_STORE_STATE.EXPENSE_TYPES] || []).filter((expenseType) => {
      return Number(expenseTypeId) !== Number(expenseType.id)
    })
    state[COST_TYPES_STORE_STATE.EXPENSE_TYPES] = expenseTypes
  }
}
