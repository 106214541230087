import { computed } from 'vue'
import { capitalize } from 'lodash-es'
import { xstore } from '@/store'
import { COST_TYPES_STORE_GETTERS, COST_TYPES_STORE_ACTIONS } from '@/enums'
import { Receipt, ExpenseType } from '@/models'

export default function useCostTypes() {
  const customCostTypes = computed<ExpenseType[]>(() => {
    return xstore.getters[COST_TYPES_STORE_GETTERS.EXPENSE_TYPES]
  })

  const loadExpenseTypes = (companyId: string | number) => {
    // TODO: Fix types
    // @ts-ignore
    return xstore.dispatch(COST_TYPES_STORE_ACTIONS.GET_EXPENSE_TYPES, companyId)
  }

  const costTypeById = (costTypeId: number | null): ExpenseType | undefined => {
    if (!costTypeId) return
    return xstore.getters[COST_TYPES_STORE_GETTERS.EXPENSE_TYPE_BY_ID](costTypeId)
  }

  const expenseTypeName = (expenseType: ExpenseType | undefined, language: string) => {
    if (expenseType?.translations && Object.values(expenseType?.translations).includes(expenseType?.name)) {
      return capitalize(expenseType.translations[language])
    }

    return expenseType?.name || ''
  }

  const setCostTypeName = (receipt: Receipt, language: string): string => {
    if (!receipt?.costType && !receipt?.costTypeId) return ''

    if (receipt.costType) return receipt.costType

    if (receipt.costTypeId) {
      const costType = costTypeById(receipt.costTypeId)
      return expenseTypeName(costType, language)
    }
    return ''
  }

  const createExpenseType = (companyId: string | number, expenseType: ExpenseType) => {
    return xstore.dispatch(COST_TYPES_STORE_ACTIONS.CREATE_EXPENSE_TYPE, {
      companyId,
      expenseType
    })
  }

  const updateExpenseType = (companyId: string | number, expenseTypeId: string | number, expenseType: ExpenseType) => {
    return xstore.dispatch(COST_TYPES_STORE_ACTIONS.UPDATE_EXPENSE_TYPE, {
      companyId,
      expenseTypeId,
      expenseType
    })
  }

  function deleteExpenseType(companyId: string | number, expenseTypeId: string | number) {
    return xstore.dispatch(COST_TYPES_STORE_ACTIONS.DELETE_EXPENSE_TYPE, {
      companyId,
      expenseTypeId
    })
  }

  return {
    customCostTypes,
    costTypeById,
    expenseTypeName,
    setCostTypeName,
    loadExpenseTypes,
    createExpenseType,
    updateExpenseType,
    deleteExpenseType
  }
}
