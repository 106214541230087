export default {
  US: {
    'US_New York': {
      eng: 'New York',
      est: 'New York',
      fin: 'New York',
      lav: 'Ņujorka',
      rus: 'Нью-Йорк',
      pol: 'Nowy Jork',
      swe: 'New York',
      lit: 'Niujorkas',
      esp: 'Nueva York'
    }
  }
}
