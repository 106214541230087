import { useInstanceHelpers } from '.'

import { validateStatus } from '@/lib/status'

import {
  TRIP_STATUS,
  TRIP_STATUS_VALIDATOR,
  TRIP_STATUS_CATEGORY,
  TRIP_STATUS_SEQUENCE,
  STATUS_COLORS,
  STATUS_BACKGROUND_COLORS
} from '@/enums'

import { Company, Trip } from '@/models'

export default function useTripStatus() {
  const { t } = useInstanceHelpers()

  const currencyConversionStatus = (trip: Trip) => {
    if (validateStatus(trip.status, [TRIP_STATUS.CURRENCY_PROCESSING])) {
      return {
        color: STATUS_COLORS.BLUE,
        label: t('processingCurrency'),
        status: TRIP_STATUS_CATEGORY.CURRENCY_PROCESSING
      }
    }

    if (validateStatus(trip.status, [TRIP_STATUS.CURRENCY_CONVERTED])) {
      return {
        color: STATUS_COLORS.GREEN,
        label: t('currencyConverted'),
        status: TRIP_STATUS_CATEGORY.CURRENCY_CONVERTED
      }
    }

    return {}
  }

  const statusInfo = (trip: Trip, company: Company) => {
    switch (true) {
      case validateStatus(trip.status, TRIP_STATUS_VALIDATOR.BOUNCED): {
        return {
          color: STATUS_COLORS.RED,
          background: STATUS_BACKGROUND_COLORS.RED,
          label: t('forwardingFailed'),
          status: TRIP_STATUS_CATEGORY.BOUNCED,
          index: TRIP_STATUS_SEQUENCE.BOUNCED
        }
      }

      case validateStatus(trip.status, TRIP_STATUS_VALIDATOR.SENT) &&
        validateStatus(trip.status, TRIP_STATUS_VALIDATOR.APPROVED): {
        return {
          color: STATUS_COLORS.GREEN,
          background: STATUS_BACKGROUND_COLORS.GREEN,
          label: t('approvedAndSent'),
          status: TRIP_STATUS_CATEGORY.APPROVED_AND_SENT,
          index: TRIP_STATUS_SEQUENCE.APPROVED_AND_SENT
        }
      }

      case validateStatus(trip.status, TRIP_STATUS_VALIDATOR.SENT): {
        return {
          color: STATUS_COLORS.GREEN,
          background: STATUS_BACKGROUND_COLORS.GREEN,
          label: t('forwardingSuccess'),
          status: TRIP_STATUS_CATEGORY.SENT,
          index: TRIP_STATUS_SEQUENCE.SENT
        }
      }

      case validateStatus(trip.status, TRIP_STATUS_VALIDATOR.APPROVING): {
        return {
          color: STATUS_COLORS.BLUE,
          background: STATUS_BACKGROUND_COLORS.BLUE,
          label: t('approving'),
          status: TRIP_STATUS_CATEGORY.APPROVING,
          index: TRIP_STATUS_SEQUENCE.APPROVING
        }
      }

      case validateStatus(trip.status, TRIP_STATUS_VALIDATOR.APPROVED): {
        return {
          color: STATUS_COLORS.GREEN,
          background: STATUS_BACKGROUND_COLORS.GREEN,
          label: t('approved'),
          status: TRIP_STATUS_CATEGORY.APPROVED,
          index: TRIP_STATUS_SEQUENCE.APPROVED
        }
      }

      case validateStatus(trip.status, TRIP_STATUS_VALIDATOR.REJECTED): {
        return {
          color: STATUS_COLORS.RED,
          background: STATUS_BACKGROUND_COLORS.RED,
          label: t('rejected'),
          status: TRIP_STATUS_CATEGORY.REJECTED,
          index: TRIP_STATUS_SEQUENCE.REJECTED
        }
      }

      case !validateStatus(trip.status, [TRIP_STATUS.CONFIRMED]): {
        return {
          color: STATUS_COLORS.GREY,
          background: STATUS_BACKGROUND_COLORS.GREY,
          label: t('draft'),
          status: TRIP_STATUS_CATEGORY.DRAFT,
          index: TRIP_STATUS_SEQUENCE.DRAFT
        }
      }

      case validateStatus(trip.status, TRIP_STATUS_VALIDATOR.PROCESSING): {
        return {
          color: STATUS_COLORS.BLUE,
          background: STATUS_BACKGROUND_COLORS.BLUE,
          label: t('tripProcessing'),
          status: TRIP_STATUS_CATEGORY.PROCESSING,
          index: TRIP_STATUS_SEQUENCE.PROCESSING
        }
      }

      case validateStatus(trip.status, [TRIP_STATUS.DIGITIZING]): {
        return {
          color: STATUS_COLORS.BLUE,
          background: STATUS_BACKGROUND_COLORS.BLUE,
          label: t('digitizing'),
          status: TRIP_STATUS_CATEGORY.DIGITIZING,
          index: TRIP_STATUS_SEQUENCE.DIGITIZING
        }
      }

      case validateStatus(trip.status, TRIP_STATUS_VALIDATOR.CONFIRMED) &&
        company?.settings?.requireReportConfirmation: {
        return {
          color: STATUS_COLORS.BLUE,
          background: STATUS_BACKGROUND_COLORS.BLUE,
          label: t('pendingConfirmation'),
          status: TRIP_STATUS_CATEGORY.CONFIRMING,
          index: TRIP_STATUS_SEQUENCE.CONFIRMING
        }
      }

      case validateStatus(trip.status, TRIP_STATUS_VALIDATOR.CONFIRMED): {
        return {
          color: STATUS_COLORS.BLUE,
          background: STATUS_BACKGROUND_COLORS.BLUE,
          label: t('submitted'),
          status: TRIP_STATUS_CATEGORY.SUBMITTED,
          index: TRIP_STATUS_SEQUENCE.SUBMITTED
        }
      }

      default: {
        return {
          color: STATUS_COLORS.GREY,
          background: STATUS_BACKGROUND_COLORS.GREY,
          label: t('draft'),
          status: TRIP_STATUS_CATEGORY.DRAFT,
          index: TRIP_STATUS_SEQUENCE.DRAFT
        }
      }
    }
  }

  return {
    statusInfo,
    currencyConversionStatus
  }
}
