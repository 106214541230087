import { CARD_REPORTING_INTERVAL, COMPANIES_STORE_STATE, PRICING_PLAN, EDITABLE_RECEIPT_KEY } from '@/enums'
import { ItemRowColumn } from './receipts'

export interface CompaniesStoreState {
  [COMPANIES_STORE_STATE.COMPANIES]: Companies
  [COMPANIES_STORE_STATE.ACTIVE_COMPANY]: Company | undefined
}

export enum COUNTRY_CODE {
  EE = 'EE',
  LV = 'LV',
  FI = 'FI',
  SE = 'SE',
  LT = 'LT',
  PL = 'PL'
}

export enum PLAN_ID {
  PAY_AS_YOU_GO = '1',
  PAY_AS_YOU_GO_FREE_DEMO = '12',
  MY_PLAN = '2',
  DISCOUNT_PLAN = '7',
  SELF_SERVICE = '10',
  FIXED_PRICE_30 = '11',
  SELF_SERVICE_FREE_DEMO = '13',
  SELF_SERVICE_EXTENDED = '14',
  SELF_SERVICE_FREE_PLAN = '15'
}

export interface CompanyRole {
  accountant: boolean
  companyId: number
  createdAt: string
  email?: string
  expectedEmail?: string
  firstName?: string
  id: number
  lang: string
  lastName?: string
  rolename: string
  state: string
  updatedAt: string
  userId?: number
}

export interface Companies {
  [key: string]: Company
}

export interface CompanySettings {
  alias?: string
  autoSubmitInboxDocuments?: boolean
  cardReportingInterval?: CARD_REPORTING_INTERVAL
  currencyAutoconversionEnabled?: boolean
  currencyAutoconversionMode?: string
  disableDistinctionCheck?: boolean
  disableSendingSingleDocuments?: boolean
  disableUserSelfJoin?: boolean
  enableApprovals?: boolean
  enableReports?: boolean
  requireExpenseConfirmation?: boolean
  requireReportConfirmation?: boolean
  usesCreditCardReports?: boolean
  usesCustomCostTypes?: boolean
  usesCustomPaymentMethods?: boolean
  'xobjt:removeSubmitter'?: boolean
  cloudViewCustomization?: {
    itemColumnOrder?: ItemRowColumn[],
    extraDocumentFields?: EDITABLE_RECEIPT_KEY[]
  }
  appViewCustomization?: {
    extraDocumentFields?: EDITABLE_RECEIPT_KEY[]
  }
}

export interface Company {
  VATAccountable: boolean
  VATNumber: any | null
  accountingContext: any
  activeFields: any[]
  address: string
  appsettings: any
  bankAccounts: any | null
  baseCurrency: string
  city: string
  companyRoles: CompanyRole[]
  country: string
  countryCode: string
  createdAt: Date
  dPreferences: any | null
  digitization: boolean
  digitizerCompanyId: number
  exid: any | null
  extraData: {
    billingEmail: string
    billingCompanyId: string | null
  }
  id: number
  legalEntityType: any | null
  metadata: any | null
  monthlyThreshold5Exceeded: boolean
  name: string
  planId: PRICING_PLAN
  planLimit: any | null
  postalCode: any | null
  receiptsThisMonthCount: string
  regCode: string
  settings: CompanySettings
  updatedAt: Date
}
