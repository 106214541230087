export enum COST_TYPES_STORE_STATE {
  EXPENSE_TYPES = 'CostTypes/EXPENSE_TYPES'
}

export enum COST_TYPES_STORE_GETTERS {
  EXPENSE_TYPE_BY_ID = 'CostTypes/EXPENSE_TYPE_BY_ID',
  EXPENSE_TYPES = 'CostTypes/EXPENSE_TYPES'
}

export enum COST_TYPES_STORE_ACTIONS {
  GET_EXPENSE_TYPES = 'CostTypes/GET_EXPENSE_TYPES',
  CREATE_EXPENSE_TYPE = 'CostTyes/CREATE_EXPENSE_TYPE',
  UPDATE_EXPENSE_TYPE = 'CostTypes/UPDATE_EXPENSE_TYPE',
  DELETE_EXPENSE_TYPE = 'CostTypes/DELETE_EXPENSE_TYPE'
}

export enum COST_TYPES_STORE_MUTATIONS {
  SET_EXPENSE_TYPES = 'CostTypes/SET_EXPENSE_TYPES',
  SET_EXPENSE_TYPE = 'CostTypes/SET_EXPENSE_TYPE',
  DELETE_EXPENSE_TYPE = 'CostTypes/DELETE_EXPENSE_TYPE'
}

export const DEFAULT_EXPENSE_TYPES = [
  {
    name: 'business cost',
    translations: {
      eng: 'business cost',
      est: 'majanduskulu',
      fin: 'liiketoimintakustannukset',
      lav: 'biznesa izmaksas',
      pol: 'koszty biznesowe',
      rus: 'экономические расходы',
      swe: 'företagskostnad',
      lit: 'verslo kaštai'
    }
  },
  {
    name: 'car',
    translations: {
      eng: 'car',
      est: 'sõiduauto',
      fin: 'auto',
      lav: 'automašīna',
      pol: 'samochód',
      rus: 'автомобиль',
      swe: 'bil',
      lit: 'automobilis'
    }
  },
  {
    name: 'other cost',
    translations: {
      eng: 'other cost',
      est: 'muu kulu',
      fin: 'muut kustannukset',
      lav: 'citas izmaksas',
      pol: 'inne koszty',
      rus: 'другие расходы',
      swe: 'övrig kostnad',
      lit: 'kiti kaštai'
    }
  },
  {
    name: 'representation cost',
    translations: {
      eng: 'representation cost',
      est: 'esinduskulu',
      fin: 'edustuskustannukset',
      lav: 'reprezentācijas izdevumi',
      pol: 'koszty reprezentacyjne',
      rus: 'представительские расходы',
      swe: 'representationskostnad',
      lit: 'reprezentaciniai kaštai'
    }
  },
  {
    name: 'transport',
    translations: {
      eng: 'transport',
      est: 'transport',
      fin: 'kuljetus',
      lav: 'cits transports',
      pol: 'transport',
      rus: 'транспорт',
      swe: 'transport',
      lit: 'transportas'
    }
  }
]
