import { Company, CompanySettings } from '@/models'
import errorHandler from '@/lib/errorHandler'
import { root } from '@/store/store.root'
import axios from 'axios'

import {
  COMPANIES_STORE_MUTATIONS,
  COMPANIES_STORE_ACTIONS,
  COMPANIES_STORE_GETTERS,
  COMPANIES_STORE_STATE
} from '@/enums'

export const companies = root.createModule('companies', {
  state: {
    [COMPANIES_STORE_STATE.COMPANIES]: {} as { [key: PropertyKey]: Company },
    [COMPANIES_STORE_STATE.ACTIVE_COMPANY]: undefined as Company | undefined
  },
  getters: {
    [COMPANIES_STORE_GETTERS.COMPANIES]: (state) => {
      return state[COMPANIES_STORE_STATE.COMPANIES]
    },
    [COMPANIES_STORE_GETTERS.ACTIVE_COMPANY]: (state) => {
      return state[COMPANIES_STORE_STATE.ACTIVE_COMPANY]
    }
  },
  mutations: {
    [COMPANIES_STORE_MUTATIONS.SET_COMPANIES]: (state, companies: Company[]) => {
      for (const company of companies) {
        if (state[COMPANIES_STORE_STATE.COMPANIES][company.id]) {
          state[COMPANIES_STORE_STATE.COMPANIES][company.id] = {
            ...state[COMPANIES_STORE_STATE.COMPANIES][company.id],
            ...company
          }
        } else {
          state[COMPANIES_STORE_STATE.COMPANIES][company.id] = company
        }
      }
    },
    [COMPANIES_STORE_MUTATIONS.SET_COMPANY_VALUES]: (
      state,
      payload: { companyId: number; values: Partial<Company>; disableCurrent?: boolean }
    ) => {
      const { companyId, values } = payload

      state[COMPANIES_STORE_STATE.COMPANIES][companyId] = {
        ...(!payload.disableCurrent ? state[COMPANIES_STORE_STATE.COMPANIES][companyId] : {} as any),
        ...values
      }
    },
    [COMPANIES_STORE_MUTATIONS.SET_COMPANY_SETTINGS]: (
      state,
      payload: { companyId: number; updatedSettings: Partial<CompanySettings> }
    ) => {
      const { companyId, updatedSettings } = payload

      if (state[COMPANIES_STORE_STATE.COMPANIES][companyId]) {
        for (const [key, value] of Object.entries(updatedSettings)) {
          //@ts-ignore
          state[COMPANIES_STORE_STATE.COMPANIES][companyId]['settings'][key] = value
        }
      }
    }
  },
  actions: {
    [COMPANIES_STORE_ACTIONS.GET_COMPANIES]: async ({ commit }) => {
      try {
        const { data: companies = [] } = await axios.get('@api/core/v1/companies?includeRoles=true')

        companies.forEach((company: any) => delete company.settings)

        commit(COMPANIES_STORE_MUTATIONS.SET_COMPANIES, companies)

        return companies
      } catch (error: any) {
        errorHandler(COMPANIES_STORE_ACTIONS.GET_COMPANIES, error)
      }
    },
    [COMPANIES_STORE_ACTIONS.GET_COMPANY_ROLES]: async (_, payload: { companyId: number }) => {
      try {
        const { data = [] } = await axios.get(`@api/core/v1/companies/${payload.companyId}/roles`)

        return data
      } catch (error: any) {
        errorHandler(COMPANIES_STORE_ACTIONS.GET_COMPANY_ROLES, error)
      }
    },
    [COMPANIES_STORE_ACTIONS.SEND_INVITE]: async (
      { commit, state },
      payload: { companyId: number; user: { language: string; email: string; isAdmin: boolean } }
    ) => {
      try {
        const { companyId, user } = payload

        const { data = {} } = await axios.post(
          `@api/core/v1/companies/${companyId}/roles?invitationLang=${user.language}`,
          {
            email: user.email,
            accountant: user.isAdmin
          }
        )

        commit(COMPANIES_STORE_MUTATIONS.SET_COMPANY_VALUES, {
          companyId,
          values: {
            companyRoles: [...(state[COMPANIES_STORE_STATE.COMPANIES]?.[companyId].companyRoles || []), data]
          }
        })

        return data
      } catch (error: any) {
        errorHandler(COMPANIES_STORE_ACTIONS.SEND_INVITE, error)
      }
    },
    [COMPANIES_STORE_ACTIONS.GET_COMPANY]: async ({ commit }: any, id: string | number) => {
      try {
        const { data: company } = await axios.get(`@api/core/v1/companies/${id}`)

        commit(COMPANIES_STORE_MUTATIONS.SET_COMPANIES, [company])

        return company
      } catch (error: any) {
        errorHandler(COMPANIES_STORE_ACTIONS.GET_COMPANY, error)
      }
    },
    [COMPANIES_STORE_ACTIONS.UPDATE_SETTINGS]: async (
      { commit },
      payload: { companyId: number; settings: Partial<CompanySettings> }
    ) => {
      try {
        const { companyId, settings } = payload

        const { data: updatedSettings = {} } = await axios.post(
          `@coreapi/companies/${companyId}/settings/features`,
          settings
        )

        commit(COMPANIES_STORE_MUTATIONS.SET_COMPANY_SETTINGS, { companyId, updatedSettings })

        return updatedSettings
      } catch (error: any) {
        errorHandler(COMPANIES_STORE_ACTIONS.UPDATE_SETTINGS, error)
        throw error
      }
    }
  }
})
