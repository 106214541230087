import { EDITABLE_RECEIPT_KEY } from '@/enums'

export default function useOCRCoordinates() {

  const getOcrCoordinates = (receipt: any) => {
    // Initialize OCRCoordinates object
    const OCRCoordinates: { [key: string]: any } = {}
    const ocrData = Object.entries(receipt?.ocr || {})

    // Process OCR data
    ocrData.forEach(([key, value]) => {
      if (key === 'bankAccounts' || key === 'supplier') {
        const data = Object.entries(value || {})
        data.forEach(([subKey, subValue]) => {
          if (subValue.source) {
            if (!OCRCoordinates[key.toLowerCase()]) {
              OCRCoordinates[key.toLowerCase()] = []
            }
            OCRCoordinates[key.toLowerCase()].push(subValue.source)
          }
        })
      } else if (key === 'totals') {
        const data = Object.entries(value || {})
        data.forEach(([subKey, subValue]) => {
          const data = Object.entries(subValue || {})
          data.forEach(([subberKey, subberValue]) => {
            if ((subberValue as { source: any })?.source) {
              OCRCoordinates[subberKey.toLowerCase()] = (subberValue as { source: any }).source
            }
          })
        })
      } else if (key === 'documentInfo') {
        if ((value as { documentId?: { source: any } })?.documentId?.source) {
          OCRCoordinates[key.toLowerCase()] = (value as { documentId?: { source: any } })?.documentId?.source
        }
      } else {
        if ((value as { source: any })?.source) {
          OCRCoordinates[key.toLowerCase()] = (value as { source: any }).source
        }
      }
    })

    const totalHeight = receipt?.ocr?.metadata?.totalHeight || false
    const imageHeight = receipt?.ocr?.metadata?.imageHeight
    // account for multiple page PDF-s
    const finalHeight = totalHeight ? totalHeight * (totalHeight / imageHeight) : imageHeight

    return {
      coordinates: OCRCoordinates,
      imageWidth: receipt?.ocr?.metadata?.imageWidth,
      imageHeight: finalHeight,
      oldPDF: !totalHeight && receipt?.originalName?.endsWith('.pdf') // for old PDF-s when the totalHeight wasnt attached to them
    }
  }

  const getDrawableOcrCoordinates = (field: string, OCRCoordinates: Array<any>) => {
    // convert to input field labels to the labels that come from OCR data
    if (
      field === EDITABLE_RECEIPT_KEY.SUPPLIER ||
      field === EDITABLE_RECEIPT_KEY.SUPPLIER_REG_NR ||
      field === EDITABLE_RECEIPT_KEY.SUPPLIER_VAT_NR
    ) {
      field = 'supplier'
    } else if (field === EDITABLE_RECEIPT_KEY.DOCUMENT_ID) {
      field = 'documentInfo'
    } else if (field === EDITABLE_RECEIPT_KEY.IBAN) {
      field = 'bankAccounts'
    }

    // Convert field to lowercase
    field = field.toLowerCase()

    let matchingField = findFromObject(OCRCoordinates, field)

    // Check if field exists in OCRCoordinates, if not the return all fields
    if (matchingField?.length) {
      return matchingField
    } else {
      // if not found, dont return anything
      return []
    }
  }

  const findFromObject = (object: Array<any>, field: string) => {
    const matches = []

    for (const [key, value] of Object.entries(object)) {
      if (key === field) {
        if (Array.isArray(value)) {
          for (const i of value) {
            matches.push(i)
          }
        } else {
          matches.push(value)
        }
      }
    }
    return matches
  }

  return {
    getOcrCoordinates,
    getDrawableOcrCoordinates
  }
}
