import { sortBy, cloneDeep } from 'lodash-es'
import { ACCOUNTING_STORE_STATE, ACCOUNTING_STORE_MUTATIONS } from '@/enums'
import { AccountingStoreState, AccountingRef, AccountingObject, AutoaccountingRule } from '@/models'

export default {
  [ACCOUNTING_STORE_MUTATIONS.SET_AUTOACCOUNTING_RULES]: (
    state: AccountingStoreState,
    payload: { companyId?: string | number; autoaccountingRules: AutoaccountingRule[] }
  ) => {
    const { companyId, autoaccountingRules } = payload

    if (!companyId) return

    state[ACCOUNTING_STORE_STATE.AUTOACCOUNTING_RULES][companyId] = autoaccountingRules
  },
  [ACCOUNTING_STORE_MUTATIONS.SET_ACCOUNTING_OBJECTS]: (
    state: AccountingStoreState,
    payload: { accountingObjects?: Array<AccountingObject>; companyId?: string | number } = {}
  ) => {
    const { companyId, accountingObjects } = payload

    if (!companyId || !accountingObjects?.length) return

    const sortedObjects = sortBy(accountingObjects, [(item: AccountingObject) => item.value])

    state[ACCOUNTING_STORE_STATE.ACCOUNTING_OBJECTS][Number(companyId)] = sortedObjects
  },
  [ACCOUNTING_STORE_MUTATIONS.SET_ACCOUNTING_OBJECT]: (
    state: AccountingStoreState,
    payload: { accountingObject: AccountingObject; companyId?: string | number }
  ) => {
    const { companyId, accountingObject } = payload

    if (!companyId || !accountingObject?.id) return

    let currentState: AccountingObject[] = cloneDeep(
      state[ACCOUNTING_STORE_STATE.ACCOUNTING_OBJECTS][Number(companyId)] || []
    )

    const existingEntryIndex = currentState?.findIndex((oneObject) => {
      return oneObject.id === accountingObject.id
    })

    if (existingEntryIndex > -1) {
      currentState[existingEntryIndex] = accountingObject
    } else {
      currentState.push(accountingObject)
    }

    const sortedObjects = sortBy(currentState, [(item: AccountingObject) => item.value])

    state[ACCOUNTING_STORE_STATE.ACCOUNTING_OBJECTS] = {
      ...state[ACCOUNTING_STORE_STATE.ACCOUNTING_OBJECTS],
      [Number(companyId)]: sortedObjects
    }
  },
  [ACCOUNTING_STORE_MUTATIONS.DELETE_ACCOUNTING_OBJECT]: (
    state: AccountingStoreState,
    payload: { accountingObjectId: string | number; companyId?: string | number }
  ) => {
    const { companyId, accountingObjectId } = payload

    if (!companyId || !accountingObjectId) return

    const existingEntryIndex = state[ACCOUNTING_STORE_STATE.ACCOUNTING_OBJECTS][Number(companyId)]?.findIndex(
      (oneObject) => {
        return oneObject.id === Number(accountingObjectId)
      }
    )

    if (existingEntryIndex > -1) {
      delete state[ACCOUNTING_STORE_STATE.ACCOUNTING_OBJECTS][Number(companyId)][existingEntryIndex]
    }
  },
  [ACCOUNTING_STORE_MUTATIONS.SET_MANAGED_REFS]: (
    state: AccountingStoreState,
    payload: {
      companyId: string | number
      managedRefs: Array<AccountingRef>
    }
  ) => {
    const { companyId, managedRefs = [] } = payload
    if (!companyId) return
    if (!state[ACCOUNTING_STORE_STATE.MANAGED_REFS][companyId]) {
      state[ACCOUNTING_STORE_STATE.MANAGED_REFS][companyId] = {}
    }
    managedRefs.forEach((accountingRef) => {
      if (accountingRef.id) {
        state[ACCOUNTING_STORE_STATE.MANAGED_REFS][companyId][accountingRef.id] = accountingRef
      }
    })
  }
}
