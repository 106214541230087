import { computed } from 'vue'
import { sortBy } from 'lodash-es'
import xstore from '@/store'
import { useContext } from '@/composables'
import { PaymentMethod, PaymentMethods } from '@/models'
import { PAYMENT_TYPE, PAYMENTS_STORE_ACTIONS, PAYMENTS_STORE_GETTERS } from '@/enums'

export default function usePaymentMethods() {
  const { root } = useContext()

  const companyId = computed(() => Number(root?.$route.params.companyId) || '')

  const paymentMethods = computed<PaymentMethods>(() => {
    return xstore.getters[PAYMENTS_STORE_GETTERS.PAYMENT_METHODS][companyId.value] || {}
  })

  const paymentMethodsForUser = (userId: number | null | undefined): PaymentMethod[] => {
    if (!userId) return []
    return sortBy(Object.values(paymentMethods.value || {}).filter((method: PaymentMethod) => {
      // CUSTOM CASH IS GETTING DEPRECATED
      return (!method.userId || method.userId == userId) && method.type !== PAYMENT_TYPE.CASH
    }), [(paymentMethod: PaymentMethod) => paymentMethod.type])
  }

  // RETURNS METHODS THAT ARE AVAILABLE FOR USER TO SELECT AVOIDING DOUBLING OPTIONS WITH CUSTOM ONES
  const defaultPaymentMethods = (userCustomPaymentMethods: PaymentMethod[]) => {
    const availableDefaultMethods = ['cash', 'cc']
    // const hasCard = userCustomPaymentMethods.find((method) => {
    //   return method.type === PAYMENT_TYPE.CREDIT_CARD || method.type === PAYMENT_TYPE.DEBIT_CARD
    // })
    // if (!hasCard) {
    //   availableDefaultMethods.push('cc')
    // }
    const hasAccount = userCustomPaymentMethods.find(method => {
      return method.type === PAYMENT_TYPE.BANK_ACCOUNT
    })
    if (!hasAccount) {
      availableDefaultMethods.push('transfer')
    }
    return availableDefaultMethods
  }

  const paymentMethodById = (id: string | number): PaymentMethod => {
    return paymentMethods.value?.[id] || {}
  }

  const loadPaymentMethods = () => {
    if (!companyId.value) return
    return xstore.dispatch(PAYMENTS_STORE_ACTIONS.GET_PAYMENT_METHODS, {
      companyId: companyId.value
    })
  }

  const createPaymentMethod = (newPaymentMethod: PaymentMethod): Promise<PaymentMethod> | undefined => {
    if (!companyId.value) return
    return xstore.dispatch(PAYMENTS_STORE_ACTIONS.CREATE_PAYMENT_METHOD, {
      companyId: companyId.value,
      newPaymentMethod
    })
  }

  const updatePaymentMethod = (paymentMethodId: string | number, updatedPaymentMethod: PaymentMethod) => {
    if (!companyId.value) return
    return xstore.dispatch(PAYMENTS_STORE_ACTIONS.UPDATE_PAYMENT_METHOD, {
      companyId: companyId.value,
      paymentMethodId,
      updatedPaymentMethod
    })
  }

  const deletePaymentMethod = (paymentMethodId: string | number) => {
    if (!companyId.value) return
    return xstore.dispatch(PAYMENTS_STORE_ACTIONS.DELETE_PAYMENT_METHOD, {
      companyId: companyId.value,
      paymentMethodId
    })
  }

  return {
    paymentMethods,
    defaultPaymentMethods,
    paymentMethodById,
    paymentMethodsForUser,
    loadPaymentMethods,
    createPaymentMethod,
    updatePaymentMethod,
    deletePaymentMethod
  }
}
