let _ = require('lodash-es'),
  moment = require('moment')

module.exports = function (tripInput, tripsInput) {
  // THIS FUNCTION RETURNS allowanceSum AND allowanceDays
  let allowanceSum = 0
  let allowanceDays = 0
  let warning = false
  let overlappingDates = {}

  // CREATE CALENDAROBJECTS FROM EXISTING TRIPS AND THE NEW TRIP
  let oldTrips = createTripCalendar(tripsInput) // {'2018-09': {'01': 50, '02': 50}}
  let newTrip = createTripCalendar([tripInput]) // {'2018-09': {'01': 50, '02': 50}}

  // ITERATE OVER EACH MONTH IN NEW TRIP
  _.each(newTrip, function (days, month) {
    // WALK OVER ALL DAYS IN THE TRIP (FROM THIS MONTH)
    _.each(days, function (val, day) {
      // HANDLE OVERLAPS WITH OLD TRIPS
      if (oldTrips?.[month]?.[day]) {
        newTrip[month][day] = 0
        warning = true
        if (!overlappingDates[month]) overlappingDates[month] = []
        overlappingDates[month].push(day.replace(/^0+(?!$)/, ''))
      }
      // ADD RATE PER DAY TO TOTAL ALLOWANCE SUM
      allowanceSum = safeAdd(allowanceSum, newTrip[month][day])
      // ADD ONE DAY TO TOTAL NUMBER OF DAYS
      allowanceDays += 1
    })
  })

  //SORT OVERLAPPING DATES IN ASCENDING ORDER
  _.each(overlappingDates, (_, month) => {
    overlappingDates[month].sort((a, b) => a - b)
  })

  let payload = {
    days: allowanceDays,
    sum: allowanceSum,
    warning,
    overlappingDates
  }
  return payload
}

const safeAdd = (value1, value2) => {
  return Number((Number(value1 || 0) + Number(value2 || 0)).toFixed(2))
}

function findAllowancePerDay(nrOfDay, year, maxAllowance) {
  // IF YEAR WAS BEFORE 2024, USE ALLOWANCE RATE FROM 2024 OR FOR YEAR AFTER USE ALLOWANCE RATE FOR 2025
  if (year < 2024) year = 2024
  if (year > 2025) year = 2025
  const rates = {
    baseAllowanceRate: {
      2024: 50,
      2025: 75
    },
    rateAfter15Days: {
      2024: 32,
      2025: 40
    }
  }
  let allowanceRate = nrOfDay > 15 ? rates.rateAfter15Days[year] : rates.baseAllowanceRate[year]
  if (maxAllowance) {
    if (maxAllowance > allowanceRate) {
      // IF MAX ALLOWANCE IS GREATER THAN ALLOWANCE RATE, OVERWRITE MAX
      maxAllowance = allowanceRate
    } else {
      // OTHERWISE OVERWRITE THE ALLOWANCE RATE
      allowanceRate = Number(maxAllowance)
    }
  }
  return allowanceRate
}

function createTripCalendar(trips) {
  const tripCalendar = {}
  // DAYS COUNTER TO TRACK IF 15 DAYS HAVE PASSED
  let currentTripDay = 0
  // WALK THROUGH ALL AVAILABLE TRIPS
  _.each(trips, function (trip) {
    // AND MARK DAYS IN CALENDAR WHERE EACH IS ACTIVE
    const startDate = moment(trip.startDate, 'YYYY-MM-DD')
    const endDate = moment(trip.endDate, 'YYYY-MM-DD')
    const maxAllowance = trip.extraData?.maxAllowance
    // IF DEPARTED AFTER 22 OR ARRIVED BEFORE 03
    if (trip.extraData) {
      // IF DEPARTED AFTER 22, JUST START FROM THE NEXT DAY
      if (trip.extraData.departAfter22 === true) {
        startDate.add(1, 'days')
      }
      // IF ARRIVED (NOT DEPARTED THOUGH) BEFORE THREE, JUST END WITH THE LAST DAY
      if (trip.extraData.departBefore03 === true) {
        endDate.subtract(1, 'days')
      }
    }
    // RETURNS NUMBER OF DAYS OF TRIP AND 1 IF SAME DAY
    const totalDaysForCalculation = endDate.diff(startDate, 'days') + 1
    // CREATE ARRAY WITH ALL ACTIVE DAYS USED IN TRIPS
    const daysInTrip = []
    for (let i = 0; i < totalDaysForCalculation; i++) {
      daysInTrip.push(startDate.format('YYYY-MM-DD').toString())
      startDate.add(1, 'days')
    }
    // TRANSFER daysInTrip RESULTS TO tripCalendar
    _.each(daysInTrip, function (oneDay) {
      currentTripDay += 1
      const day = moment(oneDay, 'YYYY-MM-DD').format('DD').toString()
      const month = moment(oneDay, 'YYYY-MM-DD').format('YYYY-MM').toString()
      const year = moment(oneDay, 'YYYY-MM-DD').format('YYYY').toString()
      if (!tripCalendar[month]) tripCalendar[month] = {}
      const rate = trip.destination == 'EE' ? 0 : findAllowancePerDay(currentTripDay, year, maxAllowance)
      tripCalendar[month][day] = rate
    })
  })
  return tripCalendar
}
