import { BILLING_TYPE, RECEIPT_STATUS_CATEGORY, TRIP_STATUS_CATEGORY, STATUS_BACKGROUND_COLORS, TRIP_TYPE } from '@/enums'
import { receiptStatuses } from '@/lib/statuses/receiptStatuses'
import { displayedTripStatus } from '@/lib/statuses/displayedTripStatus'
import tripCurrencyConversionStatus from '@/lib/statuses/tripCurrencyConversionStatus'
import { Receipt, Trip } from '@/models'
import { useCompanies, useCostTypes } from '@/composables'

export default function useFilters() {
  const { activeCompany } = useCompanies()
  const { setCostTypeName } = useCostTypes()

  const receiptFilterOptions = (receipts: Receipt[]) => {
    const filters = {
      reimbursement: {
        paidByUnspecified: {
          count: 0,
          label: 'paidByUnspecified',
          value: 'paidByUnspecified',
          color: STATUS_BACKGROUND_COLORS.BLUE
        },
        paidPersonally: {
          count: 0,
          label: 'paidPersonally',
          value: 'paidPersonally',
          color: STATUS_BACKGROUND_COLORS.BLUE
        },
        paidByCompanyFunds: {
          count: 0,
          label: 'paidByCompanyFunds',
          value: 'paidByCompanyFunds',
          color: STATUS_BACKGROUND_COLORS.BLUE
        }
      },
      status: {
        UNSUBMITTED: {
          count: 0,
          label: 'draft',
          value: RECEIPT_STATUS_CATEGORY.UNSUBMITTED,
          color: STATUS_BACKGROUND_COLORS.GREY
        },
        INVALID: {
          count: 0,
          label: 'notValid',
          value: RECEIPT_STATUS_CATEGORY.INVALID,
          color: STATUS_BACKGROUND_COLORS.RED
        },
        PICTURELESS: {
          count: 0,
          label: 'noPicture',
          value: RECEIPT_STATUS_CATEGORY.PICTURELESS,
          color: STATUS_BACKGROUND_COLORS.RED
        },
        DUPLICATE: {
          count: 0,
          label: 'notDistinct',
          value: RECEIPT_STATUS_CATEGORY.DUPLICATE,
          color: STATUS_BACKGROUND_COLORS.RED
        },
        BOUNCED: {
          count: 0,
          label: 'forwardingFailed',
          value: RECEIPT_STATUS_CATEGORY.BOUNCED,
          color: STATUS_BACKGROUND_COLORS.RED
        },
        REJECTED: {
          count: 0,
          label: 'rejected',
          value: RECEIPT_STATUS_CATEGORY.REJECTED,
          color: STATUS_BACKGROUND_COLORS.RED
        },
        DIGITIZING: {
          count: 0,
          label: 'digitizing',
          value: RECEIPT_STATUS_CATEGORY.DIGITIZING,
          color: STATUS_BACKGROUND_COLORS.BLUE
        },
        APPROVING: {
          count: 0,
          label: 'approving',
          value: RECEIPT_STATUS_CATEGORY.APPROVING,
          color: STATUS_BACKGROUND_COLORS.BLUE
        },
        CONFIRMING: {
          count: 0,
          label: 'pendingConfirmation',
          value: RECEIPT_STATUS_CATEGORY.CONFIRMING,
          color: STATUS_BACKGROUND_COLORS.BLUE
        },
        PROCESSING: {
          count: 0,
          label: 'tripProcessing',
          value: RECEIPT_STATUS_CATEGORY.PROCESSING,
          color: STATUS_BACKGROUND_COLORS.BLUE
        },
        DIGITIZED: {
          count: 0,
          label: 'digitized',
          value: RECEIPT_STATUS_CATEGORY.DIGITIZED,
          color: STATUS_BACKGROUND_COLORS.GREEN
        },
        APPROVED: {
          count: 0,
          label: 'approved',
          value: RECEIPT_STATUS_CATEGORY.APPROVED,
          color: STATUS_BACKGROUND_COLORS.GREEN
        },
        SENT: {
          count: 0,
          label: 'forwardingSuccess',
          value: RECEIPT_STATUS_CATEGORY.SENT,
          color: STATUS_BACKGROUND_COLORS.GREEN
        },
        CURRENCY_PROCESSING: {
          count: 0,
          label: 'processingCurrency',
          value: RECEIPT_STATUS_CATEGORY.CURRENCY_PROCESSING,
          color: STATUS_BACKGROUND_COLORS.YELLOW
        },
        CURRENCY_CONVERTED: {
          count: 0,
          label: 'currencyConverted',
          value: RECEIPT_STATUS_CATEGORY.CURRENCY_CONVERTED,
          color: STATUS_BACKGROUND_COLORS.GREEN
        }
      },
      costType: {}
    }

    receipts.forEach((receipt) => {
      const { billingType, docType } = receipt
      if (docType === 'nonexpense') return
      if (billingType === BILLING_TYPE.PERSONAL) {
        filters.reimbursement.paidPersonally.count += 1
      } else if (billingType === BILLING_TYPE.COMPANY) {
        filters.reimbursement.paidByCompanyFunds.count += 1
      } else {
        filters.reimbursement.paidByUnspecified.count += 1
      }

      const statuses = receiptStatuses(receipt, activeCompany.value).map((status) => status?.status)
      statuses.forEach((status) => {
        // @ts-ignore
        if (status && filters.status[status]) {
          // @ts-ignore
          filters.status[status].count += 1
        }
      })

      const costType = setCostTypeName(receipt, localStorage.language)
      if (costType) {
        // @ts-ignore
        if (filters.costType[costType]) {
          // @ts-ignore
          filters.costType[costType].count += 1
        } else {
          // @ts-ignore
          filters.costType[costType] = {
            count: 1,
            label: costType,
            value: costType,
            color: STATUS_BACKGROUND_COLORS.BLUE
          }
        }
      }
    })
    return filters
  }

  const reportFilterOptions = (trips: Trip[]) => {
    const filters = {
      reimbursement: {
        unspecifiedFunds: {
          count: 0,
          label: 'paidByUnspecified',
          value: 'unspecifiedFunds',
          color: STATUS_BACKGROUND_COLORS.BLUE
        },
        reimbursable: {
          count: 0,
          label: 'paidPersonally',
          value: 'reimbursable',
          color: STATUS_BACKGROUND_COLORS.BLUE
        },
        companyFunds: {
          count: 0,
          label: 'paidByCompanyFunds',
          value: 'companyFunds',
          color: STATUS_BACKGROUND_COLORS.BLUE
        }
      },
      status: {
        BOUNCED: {
          count: 0,
          label: 'forwardingFailed',
          value: TRIP_STATUS_CATEGORY.BOUNCED,
          color: STATUS_BACKGROUND_COLORS.RED
        },
        REJECTED: {
          count: 0,
          label: 'rejected',
          value: TRIP_STATUS_CATEGORY.REJECTED,
          color: STATUS_BACKGROUND_COLORS.RED
        },
        SUBMITTED: {
          count: 0,
          label: 'submitted',
          value: TRIP_STATUS_CATEGORY.SUBMITTED,
          color: STATUS_BACKGROUND_COLORS.BLUE
        },
        DIGITIZING: {
          count: 0,
          label: 'digitizing',
          value: TRIP_STATUS_CATEGORY.DIGITIZING,
          color: STATUS_BACKGROUND_COLORS.BLUE
        },
        APPROVING: {
          count: 0,
          label: 'approving',
          value: TRIP_STATUS_CATEGORY.APPROVING,
          color: STATUS_BACKGROUND_COLORS.BLUE
        },
        CONFIRMING: {
          count: 0,
          label: 'pendingConfirmation',
          value: TRIP_STATUS_CATEGORY.CONFIRMING,
          color: STATUS_BACKGROUND_COLORS.BLUE
        },
        PROCESSING: {
          count: 0,
          label: 'tripProcessing',
          value: TRIP_STATUS_CATEGORY.PROCESSING,
          color: STATUS_BACKGROUND_COLORS.BLUE
        },
        APPROVED: {
          count: 0,
          label: 'approved',
          value: TRIP_STATUS_CATEGORY.APPROVED,
          color: STATUS_BACKGROUND_COLORS.GREEN
        },
        SENT: {
          count: 0,
          label: 'forwardingSuccess',
          value: TRIP_STATUS_CATEGORY.SENT,
          color: STATUS_BACKGROUND_COLORS.GREEN
        },
        CURRENCY_PROCESSING: {
          count: 0,
          label: 'processingCurrency',
          value: TRIP_STATUS_CATEGORY.CURRENCY_PROCESSING,
          color: STATUS_BACKGROUND_COLORS.YELLOW
        },
        CURRENCY_CONVERTED: {
          count: 0,
          label: 'currencyConverted',
          value: TRIP_STATUS_CATEGORY.CURRENCY_CONVERTED,
          color: STATUS_BACKGROUND_COLORS.GREEN
        },
        DRAFT: {
          count: 0,
          label: 'draft',
          value: TRIP_STATUS_CATEGORY.DRAFT,
          color: STATUS_BACKGROUND_COLORS.GREY
        }
      },
      reportType: {
        [TRIP_TYPE.EXPENSE]: {
          count: 0,
          label: 'report',
          value: TRIP_TYPE.EXPENSE,
          color: STATUS_BACKGROUND_COLORS.BLUE
        },
        [TRIP_TYPE.TRAVEL]: {
          count: 0,
          label: 'travelReport',
          value: TRIP_TYPE.TRAVEL,
          color: STATUS_BACKGROUND_COLORS.BLUE
        },
        [TRIP_TYPE.MILEAGE]: {
          count: 0,
          label: 'mileageReport',
          value: TRIP_TYPE.MILEAGE,
          color: STATUS_BACKGROUND_COLORS.BLUE
        },
        [TRIP_TYPE.CREDIT_CARD]: {
          count: 0,
          label: 'creditCardReport',
          value: TRIP_TYPE.CREDIT_CARD,
          color: STATUS_BACKGROUND_COLORS.BLUE
        },
        [TRIP_TYPE.DEBIT_CARD]: {
          count: 0,
          label: 'debitCardReport',
          value: TRIP_TYPE.DEBIT_CARD,
          color: STATUS_BACKGROUND_COLORS.BLUE
        }
      }
    }

    const reimburementTypes = ['unspecifiedFunds', 'reimbursable', 'companyFunds']

    trips.forEach((trip) => {
      const statusInfo = displayedTripStatus(trip, activeCompany.value)
      // @ts-ignore
      if (statusInfo.status && filters.status[statusInfo.status]) {
        // @ts-ignore
        filters.status[statusInfo.status].count += 1
      }

      const currencyStatus = tripCurrencyConversionStatus(trip)
      // @ts-ignore
      if (currencyStatus.status && filters.status[currencyStatus.status]) {
        // @ts-ignore
        filters.status[currencyStatus.status].count += 1
      }

      if (trip.currencyTotals?.length) {
        reimburementTypes.forEach((reimbursementType) => {
          const hasReimbursementType = trip.currencyTotals.find(
            (currencyTotal: any) => currencyTotal[reimbursementType] > 0
          )
          if (hasReimbursementType) {
            // @ts-ignore
            filters.reimbursement[reimbursementType].count += 1
          }
        })
      }

      // @ts-ignore
      if (trip.subtype && filters.reportType[trip.subtype]) {
      // @ts-ignore
        filters.reportType[trip.subtype].count += 1
      // @ts-ignore
      } else if (trip.type && filters.reportType[trip.type]) {
      // @ts-ignore
        filters.reportType[trip.type].count += 1
      }
    })
    return filters
  }

  const filterReceiptsByReimbursement = (receipt: Receipt, filters: string[]) => {
    const reimbursementValue: any = {
      [BILLING_TYPE.COMPANY]: 'paidByCompanyFunds',
      [BILLING_TYPE.PERSONAL]: 'paidPersonally',
      unspecified: 'paidByUnspecified'
    }
    const paidBy = reimbursementValue[receipt.billingType || 'unspecified']
    return receipt.docType !== 'nonexpense' && filters.includes(paidBy)
  }

  const filterReportsByReimbursement = (trip: Trip, filters: string[]) => {
    let includesFilter = false
    filters.forEach((reimbursementType) => {
      if (trip.currencyTotals.find((currencyTotal: any) => currencyTotal[reimbursementType] > 0)) {
        includesFilter = true
      }
    })
    return includesFilter
  }

  return {
    receiptFilterOptions,
    reportFilterOptions,
    filterReceiptsByReimbursement,
    filterReportsByReimbursement
  }
}
