import { ComputedRef, computed } from 'vue'
import { xstore } from '@/store'
import { useInstanceHelpers, useConfig, useContext } from '@/composables'
import { CARD_REPORTING_INTERVAL, TRANSACTIONS_STORE_ACTIONS, TRANSACTIONS_STORE_GETTERS } from '@/enums'
import { BankAccountInfo, Company, User } from '@/models'

export default function useBankConnection() {
  const { t } = useInstanceHelpers()
  const { config } = useConfig()
  const { root } = useContext()

  const companyId = computed(() => Number(root?.$route.params.companyId))

  const bankAccountList: ComputedRef<BankAccountInfo[]> = computed(() => {
    return Object.values(xstore.getters[TRANSACTIONS_STORE_GETTERS.BANK_ACCOUNT_LIST][companyId.value] || []) 
  })

  const reportingIntervalOptions = [
    { title: t('weekly'), value: CARD_REPORTING_INTERVAL.WEEKLY },
    { title: t('monthly'), value: CARD_REPORTING_INTERVAL.MONTHLY },
    { title: t('yearly'), value: CARD_REPORTING_INTERVAL.YEARLY }
  ]

  const getInitialHub = (countryCode: string) => {
    return ['EE', 'LV', 'LT'].includes(countryCode) ? 'swedbank' : 'enablebanking'
  }

  const connectToBank = (company: Company, user: User, account: BankAccountInfo = {}) => {
    if (!company.id) return
    const lang = `&lang=${user?.lang || 'eng'}`
    const cid = `&cid=${company.id}`
    const redirect = `&redirect_path=${encodeURIComponent(location.pathname)}`
    let connectUrl = `${config.value.services.api.url}/integrations/v2/connect?i=psd2${cid}${lang}${redirect}`
    if (account.bic && account.iban && account.hub && account.psuType) {
      connectUrl = `${connectUrl}&a=sync&bic=${account.bic}&iban=${account.iban}&hub=${account.hub}&psuType=${account.psuType}`
    }
    window.location.href = connectUrl
  }

  const loadBankAccounts = (): Promise<BankAccountInfo[]> | undefined => {
    if (!companyId.value) return
    return xstore.dispatch(TRANSACTIONS_STORE_ACTIONS.GET_BANK_ACCOUNT_LIST, {
      companyId: companyId.value
    })
  }

  return {
    bankAccountList,
    reportingIntervalOptions,
    loadBankAccounts,
    connectToBank
  }
}
