import { computed } from 'vue'
import { USERS_STORE_GETTERS, USERS_STORE_ACTIONS } from '@/enums'
import { xstore } from '@/store'
import { User } from '@/models'
import { useContext } from '@/composables'

export default function useUser() {
  const { root } = useContext()

  const activeUser = computed<User>(() => {
    // Store return user | undefined. Thats why its using as User.
    // In general, this computed ref should reutrn User | undefined
    return xstore.getters[USERS_STORE_GETTERS.ACTIVE_USER] as unknown as User
  })

  const changeUserLanguage = async (language: string) => {
    try {
      localStorage.setItem('language', language)
      await xstore.dispatch(USERS_STORE_ACTIONS.UPDATE_USER, { lang: language })
      root?.$notification('saved', 'success', 3000)
    } catch (error) {
      root?.$notification(root.eh(error), 'error', 9000)
    }
  }

  const languages: Record<string, string> = {
    fin: 'fi',
    lav: 'lv',
    pol: 'pl',
    est: 'et',
    rus: 'ru',
    lit: 'lt',
    swe: 'sv',
    esp: 'es',
    eng: 'en'
  };

  function convertTo3LetterLanguage(languageString: string): string {
    return Object.keys(languages).find((url: string) => languages[url] === languageString) ?? "eng";
  }

  function convertTo2LetterLanguage(languageString: string): string {
    return languages[languageString] ?? languages["eng"];
  }

  return {
    activeUser,
    languages,
    changeUserLanguage,
    convertTo3LetterLanguage,
    convertTo2LetterLanguage
  }
}
