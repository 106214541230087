export enum TRIPS_STORE_STATE {
  TRIPS = 'Trips/TRIPS',
  ALLOWANCE = 'Trips/ALLOWANCE',
  STATEMENT = 'Trips/STATEMENT',
  STATEMENT_FORMATS = 'Trips/STATEMENT_FORMATS'
}

export enum TRIPS_STORE_MUTATIONS {
  SET_TRIPS = 'Trips/SET_TRIPS',
  SET_TRIP_VALUES = 'Trips/SET_TRIP_VALUES',
  REMOVE_TRIP = 'Trips/REMOVE_TRIP',
  SET_DAILY_ALLOWANCE = 'Trips/SET_DAILY_ALLOWANCE',
  SET_DAILY_ALLOWANCE_ITEM = 'Trips/SET_DAILY_ALLOWANCE_ITEM',
  REMOVE_DAILY_ALLOWANCE_ITEM = 'Trips/REMOVE_DAILY_ALLOWANCE_ITEM',
  SET_STATEMENT = 'Trips/SET_STATEMENT',
  REMOVE_STATEMENT = 'Trips/REMOVE_STATEMENT',
  SET_STATEMENT_TRANSACTION = 'Trips/SET_STATEMENT_TRANSACTION',
  SET_STATEMENT_FORMATS = 'Trips/SET_STATEMENT_FORMATS'
}

export enum TRIPS_STORE_ACTIONS {
  GET_FILTERED_TRIPS = 'Trips/GET_FILTERED_TRIPS',
  GET_UNSUBMITTED_CARD_REPORTS = 'Trips/GET_UNSUBMITTED_CARD_REPORTS',
  UPDATE_TRIP_VALUES = 'Trips/UPDATE_TRIP_VALUES',
  DELETE_TRIP = 'Trips/DELETE_TRIP',
  REMOVE_TRIP = 'Trips/REMOVE_TRIP',
  GET_TRIP = 'Trips/GET_TRIP',
  NEW_TRIP = 'Trips/NEW_TRIP',
  EXPORT_TRIP = 'Trips/EXPORT_TRIP',
  IMPORT_TRIP = 'Trips/IMPORT_TRIP',
  GET_ALLOWANCE = 'Trips/GET_ALLOWNACE',
  SAVE_ALLOWANCE = 'Trips/SAVE_ALLOWANCE',
  UPDATE_ALLOWANCE = 'Trips/UPDATE_ALLOWANCE',
  DELETE_ALLOWANCE = 'Trips/DELETE_ALLOWANCE',
  GET_STATEMENT = 'Trips/GET_STATEMENT',
  DELETE_STATEMENT = 'Trips/DELETE_STATEMENT',
  GET_STATEMENT_FORMATS = 'Trips/GET_STATEMENT_FORMATS',
  SUBMIT_TRIP = 'Trips/SUBMIT_TRIP',
  UNSUBMIT_TRIP = 'Trips/UNSUBMIT_TRIP',
  RESUBMIT_TRIP = 'Trips/RESUBMIT_TRIP',
  SEND_TRIP_TO_SOFTWARE = 'Trips/SEND_TRIP_TO_SOFTWARE',
  CONFIRM_AND_SEND = 'Trips/CONFIRM_AND_SEND'
}

export enum TRIPS_STORE_GETTERS {
  TRIPS = 'Trips/TRIPS',
  ALLOWANCE = 'Trips/ALLOWANCE',
  STATEMENT = 'Trips/STATEMENT',
  STATEMENT_FORMATS = 'Trips/STATEMENT_FORMATS'
}

export enum TRIP_TYPE {
  EXPENSE = 'expense',
  TRAVEL = 'travel',
  MILEAGE = 'mileage',
  CREDIT_CARD = 'creditcard',
  DEBIT_CARD = 'debitcard'
}

export enum TRIP_ACTION {
  ADD_DOCUMENT = 'ADD_DOCUMENT',
  ADD_MILEAGE = 'ADD_MILEAGE',
  SUBMIT = 'SUBMIT',
  SEND = 'SEND',
  CONFIRM_AND_SEND = 'CONFIRM_AND_SEND',
  EXPORT = 'EXPORT',
  EXPORT_AND_COPY = 'EXPORT_AND_COPY',
  UNARCHIVE = 'UNARCHIVE',
  EDIT = 'EDIT',
  COPY_EMAIL = 'COPY_EMAIL',
  DELETE = 'DELETE'
}

export enum TRIP_CONTENT_TAB {
  RECEIPTS = 'receipts',
  MILEAGE = 'mileage'
}

export enum TRIP_STATUS {
  NONE = 0,
  CONFIRMED = 1,
  PROCESSING = 2,
  SENT = 4,
  BOUNCED = 8,
  DIGITIZING = 16,
  DIGITIZED = 32,
  APPROVING = 64,
  APPROVED = 128,
  REJECTED = 256,
  READY = 512,
  CURRENCY_PROCESSING = 1024,
  CURRENCY_CONVERTED = 2048
}

export enum TRIP_STATUS_CATEGORY {
  SUBMITTED = 'SUBMITTED',
  PROCESSING = 'PROCESSING',
  DIGITIZING = 'DIGITIZING',
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  APPROVING = 'APPROVING',
  APPROVED = 'APPROVED',
  APPROVED_AND_SENT = 'APPROVED_AND_SENT',
  BOUNCED = 'BOUNCED',
  REJECTED = 'REJECTED',
  CONFIRMING = 'CONFIRMING',
  CURRENCY_PROCESSING = 'CURRENCY_PROCESSING',
  CURRENCY_CONVERTED = 'CURRENCY_CONVERTED'
}

export enum TRIP_STATUS_SEQUENCE {
  SENT = 1,
  APPROVED_AND_SENT = 2,
  APPROVED = 3,
  CONFIRMING = 4,
  APPROVING = 5,
  DIGITIZING = 6,
  PROCESSING = 7,
  SUBMITTED = 8,
  REJECTED = 9,
  BOUNCED = 10,
  DRAFT = 11
}

export const TRIP_STATUS_VALIDATOR = {
  NONE: [
    -TRIP_STATUS.CONFIRMED,
    -TRIP_STATUS.PROCESSING,
    -TRIP_STATUS.SENT,
    -TRIP_STATUS.BOUNCED,
    -TRIP_STATUS.APPROVING,
    -TRIP_STATUS.APPROVED,
    -TRIP_STATUS.REJECTED,
    -TRIP_STATUS.READY
  ],
  CONFIRMED: [+TRIP_STATUS.CONFIRMED, -TRIP_STATUS.READY, -TRIP_STATUS.SENT, -TRIP_STATUS.BOUNCED],
  PROCESSING: [+TRIP_STATUS.PROCESSING, -TRIP_STATUS.READY, -TRIP_STATUS.BOUNCED, -TRIP_STATUS.SENT],
  APPROVING: [+TRIP_STATUS.APPROVING, -TRIP_STATUS.BOUNCED, -TRIP_STATUS.SENT],
  APPROVED: [+TRIP_STATUS.APPROVED],
  REJECTED: [+TRIP_STATUS.REJECTED, -TRIP_STATUS.BOUNCED, -TRIP_STATUS.SENT],
  READY: [+TRIP_STATUS.READY, -TRIP_STATUS.BOUNCED, -TRIP_STATUS.SENT],
  SENT: [+TRIP_STATUS.CONFIRMED, +TRIP_STATUS.SENT, -TRIP_STATUS.BOUNCED],
  BOUNCED: [+TRIP_STATUS.BOUNCED]
}

export enum TRAVEL_REPORT_FIELDS {
  DESTINATION = 'destination',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  START_TIME = 'startTime',
  END_TIME = 'endTime',
  DEPART_BEFORE_3 = 'departBefore03',
  DEPART_AFTER_22 = 'departAfter22',
  ONE_FREE_MEAL = 'oneFreeMeal',
  TWO_FREE_MEALS = 'twoFreeMeals',
  MEAL_AND_ACCOMODATION = 'mealAndAccomodation',
  RETURN_OVERNIGHT = 'returnOvernight',
  CALCULATE_WEEKENDS = 'calculateWeekends',
  BREAKFAST_INCLUDED = 'breakfastIncluded',
  LUNCH_INCLUDED = 'lunchIncluded',
  DINNER_INCLUDED = 'dinnerIncluded',
  NEXT_TRIP_ON_LAST_DAY = 'nextTripOnLastDay',
  MAX_ALLOWANCE = 'maxAllowance'
}

export const TRIP_CHECKBOX_FIELD_LABEL = {
  [TRAVEL_REPORT_FIELDS.DEPART_BEFORE_3]: 'arrivedBefore',
  [TRAVEL_REPORT_FIELDS.DEPART_AFTER_22]: 'businessTripStarted',
  [TRAVEL_REPORT_FIELDS.MEAL_AND_ACCOMODATION]: 'accomodationAndFreeMeal',
  [TRAVEL_REPORT_FIELDS.RETURN_OVERNIGHT]: 'returnedHomeOvernight',
  [TRAVEL_REPORT_FIELDS.CALCULATE_WEEKENDS]: 'calculateAllowanceWeekends',
  [TRAVEL_REPORT_FIELDS.BREAKFAST_INCLUDED]: 'breakfastIncluded',
  [TRAVEL_REPORT_FIELDS.LUNCH_INCLUDED]: 'lunchIncluded',
  [TRAVEL_REPORT_FIELDS.DINNER_INCLUDED]: 'dinnerIncluded',
  [TRAVEL_REPORT_FIELDS.NEXT_TRIP_ON_LAST_DAY]: 'nextTripOnLastDay'
}
