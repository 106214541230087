import { COUNTRY_CODE, MileageCalculationData } from '@/models'
import useMileageUtils from './useMileageUtils'

export default function useMileageCalculator() {
  const { isCompanyCar } = useMileageUtils()

  const ratesPerKm = {
    [COUNTRY_CODE.EE]: {
      2024: 0.3,
      2025: 0.5
    }
  }

  const limitPerMonthEstonia = {
    2024: 335,
    2025: 550
  }

  const calculateMileageEstonia = (data: MileageCalculationData) => {
    let { year, vehicle, distance, customMileageRate, sumThisMonth, calcMileageOverLimit } = data
    if (Number(year) < 2024) year = 2024
    if (Number(year) > 2025) year = 2025
    if (isCompanyCar(vehicle)) {
      return 0
    }
    // @ts-ignore
    let mileageRate = ratesPerKm[COUNTRY_CODE.EE][year]
    if (customMileageRate) {
      mileageRate = Number(customMileageRate)
    }
    let sum = Number(Number(distance * mileageRate).toFixed(2))
    //@ts-ignore
    const limit = limitPerMonthEstonia[year]
    const remainingCompensation = limit - (sumThisMonth || 0)
    if (sum > remainingCompensation && !calcMileageOverLimit) {
      sum = remainingCompensation
    }
    if (sum < 0) sum = 0
    return sum
  }

  const mileageCalculator = (countryCode: COUNTRY_CODE, data: MileageCalculationData): number => {
    switch (countryCode) {
      case COUNTRY_CODE.EE:
        return calculateMileageEstonia(data)
      default:
        return 0
    }
  }

  return {
    limitPerMonthEstonia,
    ratesPerKm,
    mileageCalculator
  }
}
