export enum APPROVAL_SETTINGS_STORE_STATE {
  APPROVAL_WORKFLOWS = 'ApprovalSettings/APPROVAL_WORKFLOWS',
  APPROVAL_POLICIES = 'ApprovalSettings/APPROVAL_POLICIES',
  APPROVAL_OPTIONS = 'ApprovalSettings/APPROVAL_OPTIONS'
}

export enum APPROVAL_SETTINGS_STORE_GETTERS {
  APPROVAL_WORKFLOWS = 'ApprovalSettings/APPROVAL_WORKFLOWS',
  APPROVAL_POLICIES = 'ApprovalSettings/APPROVAL_POLICIES',
  APPROVAL_WORKFLOW = 'ApprovalSettings/APPROVAL_WORKFLOW',
  APPROVAL_POLICY = 'ApprovalSettings/APPROVAL_POLICY',
  APPROVAL_OPTIONS = 'ApprovalSettings/APPROVAL_OPTIONS'
}

export enum APPROVAL_SETTINGS_STORE_ACTIONS {
  GET_WORKFLOWS = 'ApprovalSettings/GET_WORKFLOWS',
  GET_WORKFLOW = 'ApprovalSettings/GET_WORKFLOW',
  GET_POLICIES = 'ApprovalSettings/GET_POLICIES',
  GET_APPROVAL_OPTIONS = 'ApprovalSettings/GET_APPROVAL_OPTIONS',
  CREATE_WORKFLOW = 'ApprovalSettings/CREATE_WORKFLOW',
  CREATE_POLICY = 'ApprovalSettings/CREATE_POLICY',
  CREATE_ROUND = 'ApprovalSettings/CREATE_ROUND',
  ADD_ROUND_APPROVER = 'ApprovalSettings/ADD_ROUND_APPROVER',
  UPDATE_WORKFLOW = 'ApprovalSettings/UPDATE_WORKFLOW',
  DELETE_ROUND_APPROVER = 'ApprovalSettings/DELETE_ROUND_APPROVER',
  DELETE_ROUND = 'ApprovalSettings/DELETE_ROUND',
  DELETE_TARGET = 'ApprovalSettings/DELETE_TARGET',
  DELETE_WORKFLOW = 'ApprovalSettings/DELETE_WORKFLOW',
  DELETE_POLICY = 'ApprovalSettings/DELETE_POLICY'
}

export enum APPROVAL_SETTINGS_STORE_MUTATIONS {
  SET_APPROVAL_WORKFLOWS = 'ApprovalSettings/SET_APPROVAL_WORKFLOWS',
  SET_APPROVAL_POLICIES = 'ApprovalSettings/SET_APPROVAL_POLICIES',
  SET_APPROVAL_WORKFLOW = 'ApprovalSettings/SET_APPROVAL_WORKFLOW',
  SET_WORKFLOW_VALUE = 'ApprovalSettings/SET_WORKFLOW_VALUE',
  SET_APPROVAL_POLICY = 'ApprovalSettings/SET_APPROVAL_POLICY',
  SET_APPROVAL_ROUND = 'ApprovalSettings/SET_APPROVAL_ROUND',
  SET_APPROVAL_OPTIONS = 'ApprovalSettings/SET_APPROVAL_OPTIONS',
  DELETE_WORKFLOW = 'ApprovalSettings/DELETE_WORKFLOW',
  DELETE_POLICY = 'ApprovalSettings/DELETE_POLICY'
}

export enum APPROVAL_SCOPE {
  ROUND = 'ROUND',
  WORKFLOW = 'WORKFLOW',
  COMPANY = 'COMPANY'
}

export enum POLICY {
  TARGET_SUBMITTERS = 'TARGET_SUBMITTERS',
  MIN_AUTOAPPORVAL_SUM = 'MIN_AUTOAPPORVAL_SUM',
  MAX_APPROVABLE_SUM = 'MAX_APPROVABLE_SUM',
  DISABLE_NOTIFICATIONS = 'DISABLE_NOTIFICATIONS',
  NOTIFICATION_SCHEDULE  = 'NOTIFICATION_SCHEDULE',
  REMINDER_INTERVAL_DAYS  = 'REMINDER_INTERVAL_DAYS',
  OUT_OF_WORKFLOW_APPROVAL_ACTION = 'OUT_OF_WORKFLOW_APPROVAL_ACTION',
  ALLOW_ANYONE_AS_DELEGATE = 'ALLOW_ANYONE_AS_DELEGATE',
  NUM_OF_REQUIRED_APPROVALS = 'NUM_OF_REQUIRED_APPROVALS',
  TARGET_TYPE = 'TARGET_TYPE',
  APPROVAL_DEFAULT = 'APPROVAL_DEFAULT',
  TARGET_SUM = 'TARGET_SUM',
  TARGET_MINIMUM_SUM = 'TARGET_MINIMUM_SUM',
  TARGET_MAXIMUM_SUM = 'TARGET_MAXIMUM_SUM',
  TARGET_COST_TYPES = 'TARGET_COST_TYPES',
  SPENDING_LIMIT = 'SPENDING_LIMIT',
  MAX_MONTHLY_SPENDING_LIMIT = 'MAX_MONTHLY_SPENDING_LIMIT',
  MAX_QUARTERLY_SPENDING_LIMIT = 'MAX_QUARTERLY_SPENDING_LIMIT',
  MAX_YEARLY_SPENDING_LIMIT = 'MAX_YEARLY_SPENDING_LIMIT',
  INPUT_ACCOUNTING_FIELDS = 'INPUT_ACCOUNTING_FIELDS'
}

export enum POLICY_TITLE {
  TARGET_SUBMITTERS = 'addTargetSubmitters',
  MIN_AUTOAPPORVAL_SUM = 'minAutoApprovalSum',
  MAX_APPROVABLE_SUM = 'maxApprovableSum',
  DISABLE_NOTIFICATIONS = 'disableNotifications',
  NOTIFICATION_SCHEDULE = 'notificationSchedule',
  REMINDER_INTERVAL_DAYS = 'reminderIntervalDays',
  OUT_OF_WORKFLOW_APPROVAL_ACTION = 'approvalDefault', //'outOfWorkflowAction',
  ALLOW_ANYONE_AS_DELEGATE = 'allowAnyoneAsDelegate',
  NUM_OF_REQUIRED_APPROVALS = 'numOfRequiredApprovals',
  TARGET_TYPE = 'targetType',
  APPROVAL_DEFAULT = 'approvalDefault',
  TARGET_SUM = 'targetSum',
  TARGET_MINIMUM_SUM = 'targetMinimumSum',
  TARGET_MAXIMUM_SUM = 'targetMaximumSum',
  TARGET_COST_TYPES = 'targetExpenseTypes',
  SPENDING_LIMIT = 'spendingLimit',
  MAX_MONTHLY_SPENDING_LIMIT = 'monthlySpendingLimit',
  MAX_QUARTERLY_SPENDING_LIMIT = 'quarterlySpendingLimit',
  MAX_YEARLY_SPENDING_LIMIT = 'yearlySpendingLimit',
  INPUT_ACCOUNTING_FIELDS = 'approverInput'
}

export enum POLICY_DESCRIPTION {
  MIN_AUTOAPPORVAL_SUM = 'minAutoApprSumDescr',
  MAX_APPROVABLE_SUM = 'maxApprSumDescr',
  // DISABLE_NOTIFICATIONS = 'disableNotificDescr',
  NOTIFICATION_SCHEDULE = 'notificationScheduleDescr',
  OUT_OF_WORKFLOW_APPROVAL_ACTION = 'nonWorkflowActionDescr',
  ALLOW_ANYONE_AS_DELEGATE = 'allowAnyoneDelegateDescr',
  NUM_OF_REQUIRED_APPROVALS = 'numRequiredApprDescr',
  TARGET_TYPE = 'targetTypeDescr',
  APPROVAL_DEFAULT = 'approvalDefaultDescr',
  TARGET_SUM = 'targetSumDescr',
  TARGET_MINIMUM_SUM = 'targetSumDescr',
  TARGET_MAXIMUM_SUM = 'targetSumDescr',
  TARGET_COST_TYPES = 'targetExpenseTypesDescr',
  SPENDING_LIMIT = 'spendingLimitDescr',
  MAX_MONTHLY_SPENDING_LIMIT = 'spendingLimitDescr',
  MAX_QUARTERLY_SPENDING_LIMIT = 'spendingLimitDescr',
  MAX_YEARLY_SPENDING_LIMIT = 'spendingLimitDescr',
  INPUT_ACCOUNTING_FIELDS = 'approverInputDescr'
}

export enum COMPANY_CURRENCY {
  DEFAULT = 'EUR',
  SE = 'SEK',
  PL = 'PLN'
}

export enum SUBJECT_ROLENAME {
  SUBMITTER = 'allSubmitters',
  ADMIN = 'allAdmins'
}

export enum SPENDING_LIMIT {
  MAX_MONTHLY_SPENDING_LIMIT = 'monthlySpendingLimit',
  MAX_QUARTERLY_SPENDING_LIMIT = 'quarterlySpendingLimit',
  MAX_YEARLY_SPENDING_LIMIT = 'yearlySpendingLimit'
}

export enum TARGET_SUM {
  TARGET_MINIMUM_SUM = 'TARGET_MINIMUM_SUM',
  TARGET_MAXIMUM_SUM = 'TARGET_MAXIMUM_SUM'
}

export enum APPROVAL_DEFAULT {
  REJECT = 'reject',
  APPROVE = 'approve'
}

export enum OUT_OF_WORKFLOW_APPROVAL_ACTION {
  REJECT = 'reject',
  APPROVE = 'approve'
}

export enum TARGET_SUBTYPE {
  DAILY_ALLOWANCE = 'dailyAllowance',
  ADVANCE_PAYMENT = 'advancePayment'
}

export enum TARGET_TYPE {
  DOCUMENT = 'receipts',
  RECEIPT = 'onlyReceipts',
  INVOICE = 'invoices',
  EINVOICE = 'einvoices',
  EMAIL = 'submittedByEmail',
  REPORT = 'reports',
  EXPENSE_REPORT = 'expenseReports',
  TRAVEL_REPORT = 'travelReports',
  MILEAGE_REPORT = 'mileageReports',
  CREDIT_CARD_REPORT = 'creditCardReports'
}

export enum TARGET_TYPE_PARENT {
  RECEIPT = 'DOCUMENT',
  INVOICE = 'DOCUMENT',
  EINVOICE = 'DOCUMENT',
  EMAIL = 'DOCUMENT',
  EXPENSE_REPORT = 'REPORT',
  TRAVEL_REPORT = 'REPORT',
  MILEAGE_REPORT = 'REPORT',
  CREDIT_CARD_REPORT = 'REPORT'
}

export const enumOptions = {
  OUT_OF_WORKFLOW_APPROVAL_ACTION,
  TARGET_SUBTYPE,
  TARGET_TYPE,
  APPROVAL_DEFAULT
}
