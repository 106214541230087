import Axios from 'axios'
import {
  ACCOUNTING_OBJECT_TYPE,
  ACCOUNTING_STORE_ACTIONS,
  ACCOUNTING_STORE_MUTATIONS,
  CONFIG_STORE_GETTERS,
  INTEGRATION,
  INTEGRATIONS_STORE_MUTATIONS
} from '@/enums'
import { AccountingObject, AccountingRef, AutoaccountingRule, Integration } from '@/models'

export default {
  [ACCOUNTING_STORE_ACTIONS.ENABLE_AUTOACCOUNTING]: async (
    {},
    payload: {
      companyId: string | number
      integrationId: INTEGRATION
      enable: boolean
    }
  ): Promise<any> => {
    try {
      const { companyId, integrationId, enable } = payload

      const { data } = await Axios.post(`@api/integrations/v2/accounting/${companyId}/${integrationId}/automation`, {
        enable
      })

      return data
    } catch (error) {
      throw error
    }
  },
  [ACCOUNTING_STORE_ACTIONS.GET_AUTOMATION_RULES]: async (
    { commit }: any,
    payload: { companyId: string | number; integrationId: INTEGRATION }
  ): Promise<AutoaccountingRule[]> => {
    try {
      const { companyId, integrationId } = payload

      const { data: autoaccountingRules } = await Axios.get(
        `@api/integrations/v2/accounting/${companyId}/${integrationId}/automation/rules`
      )

      commit(ACCOUNTING_STORE_MUTATIONS.SET_AUTOACCOUNTING_RULES, { companyId, autoaccountingRules })

      return autoaccountingRules
    } catch (error) {
      throw error
    }
  },
  [ACCOUNTING_STORE_ACTIONS.UPDATE_AUTOMATION_RULES]: async (
    { commit }: any,
    payload: { companyId: string | number; integrationId: INTEGRATION; updatedRules: AutoaccountingRule[] }
  ): Promise<AutoaccountingRule[]> => {
    //POST /integrations/v2/accounting/{companyId}/{integrationId}/automation/rules.
    try {
      const { companyId, integrationId, updatedRules } = payload

      const { data: autoaccountingRules } = await Axios.post(
        `@api/integrations/v2/accounting/${companyId}/${integrationId}/automation/rules`,
        updatedRules
      )

      commit(ACCOUNTING_STORE_MUTATIONS.SET_AUTOACCOUNTING_RULES, { companyId, autoaccountingRules })

      return autoaccountingRules
    } catch (error) {
      throw error
    }
  },
  [ACCOUNTING_STORE_ACTIONS.GET_ACCOUNTING_OBJECTS]: async (
    { commit }: any,
    payload: { companyId: string | number; accountingContextSyncEnabled: boolean; integrationId: INTEGRATION }
  ): Promise<AccountingObject> => {
    try {
      const { companyId, accountingContextSyncEnabled, integrationId } = payload

      const url = `@coreapi/companies/${companyId}/accounting/${
        accountingContextSyncEnabled ? `${integrationId || '-'}` : '-'
      }`

      const { data: accountingObjects = [] } = await Axios.get(url)

      commit(ACCOUNTING_STORE_MUTATIONS.SET_ACCOUNTING_OBJECTS, { accountingObjects, companyId })

      return accountingObjects
    } catch (error) {
      throw error
    }
  },
  [ACCOUNTING_STORE_ACTIONS.CREATE_ACCOUNTING_OBJECT]: async (
    { commit }: any,
    payload: { companyId: string | number; newAccountingObject: AccountingObject }
  ): Promise<AccountingObject> => {
    try {
      const { companyId, newAccountingObject } = payload

      const url = `@coreapi/companies/${companyId}/accounting`

      if (!newAccountingObject.subtype) {
        delete newAccountingObject.subtype
      }

      const { data: accountingObject } = await Axios.post(url, newAccountingObject)

      commit(ACCOUNTING_STORE_MUTATIONS.SET_ACCOUNTING_OBJECT, { companyId, accountingObject })

      return accountingObject
    } catch (error) {
      throw error
    }
  },
  [ACCOUNTING_STORE_ACTIONS.UPDATE_ACCOUNTING_OBJECT]: async (
    { commit }: any,
    payload: { companyId: string | number; updatedAccountingObject: AccountingObject }
  ): Promise<AccountingObject> => {
    try {
      const { companyId, updatedAccountingObject } = payload

      const accountingObjectId = updatedAccountingObject.id

      delete updatedAccountingObject.id

      const url = `@coreapi/companies/${companyId}/accounting/${accountingObjectId}`

      const { data: accountingObject } = await Axios.put(url, updatedAccountingObject)

      delete accountingObject.companyId

      commit(ACCOUNTING_STORE_MUTATIONS.SET_ACCOUNTING_OBJECT, { companyId, accountingObject })

      return accountingObject
    } catch (error) {
      throw error
    }
  },
  [ACCOUNTING_STORE_ACTIONS.DELETE_ACCOUNTING_OBJECT]: async (
    { commit }: any,
    payload: { companyId: string | number; accountingObjectId: string | number }
  ): Promise<boolean> => {
    try {
      const { companyId, accountingObjectId } = payload

      const url = `@coreapi/companies/${companyId}/accounting/${accountingObjectId}`

      const { data } = await Axios.delete(url)

      commit(ACCOUNTING_STORE_MUTATIONS.DELETE_ACCOUNTING_OBJECT, { companyId, accountingObjectId })

      return true
    } catch (error) {
      throw error
    }
  },
  [ACCOUNTING_STORE_ACTIONS.UPDATE_ACCOUNTING_CONFIG]: async (
    { commit }: any,
    payload: { companyId: string | number; updatedConfig: Integration['configuration']; integration: Integration }
  ): Promise<Integration> => {
    const { companyId, updatedConfig, integration } = payload

    const url = `@api/integrations/v2/accounting/${companyId}/${integration.id}`

    const { data: updatedIntegration } = await Axios.post(url, updatedConfig)

    commit(INTEGRATIONS_STORE_MUTATIONS.SET_ACTIVE_INTEGRATION, {
      ...integration,
      configuration: {
        ...integration.configuration,
        accountingFields: updatedIntegration.configuration.accountingFields
      }
    })

    return updatedIntegration
  },
  [ACCOUNTING_STORE_ACTIONS.GET_MANAGED_REFS]: async (
    { commit }: any,
    payload: { companyId: string | number }
  ): Promise<AccountingRef[]> => {
    try {
      const { companyId } = payload

      const url = `@coreapi/companies/${companyId}/accounting/refs/managed`

      const { data: managedRefs = [] } = await Axios.get(url)

      commit(ACCOUNTING_STORE_MUTATIONS.SET_MANAGED_REFS, { companyId, managedRefs })

      return managedRefs
    } catch (error) {
      throw error
    }
  },
  [ACCOUNTING_STORE_ACTIONS.DOWNLOAD_CONTEXT_TEMPLATE]: async (
    { getters }: any,
    payload: {
      companyId: string | number
      integrationId: INTEGRATION
      lang?: string
    }
  ): Promise<void> => {
    try {
      const { companyId, integrationId, lang = 'eng' } = payload

      const { services } = getters[CONFIG_STORE_GETTERS.CONFIG]

      const token = localStorage.token

      const url = `${services.api.url}/integrations/v2/accounting/${companyId}/${integrationId}/context/csv/template?lang=${lang}&access_token=${token}`

      window.open(url, '_blank')
    } catch (error) {
      throw error
    }
  },
  [ACCOUNTING_STORE_ACTIONS.UPDATE_CONTEXT_FROM_CSV]: async (
    { commit, getters }: any,
    payload: {
      companyId: string | number
      integrationId: INTEGRATION
      accountingObjectType: ACCOUNTING_OBJECT_TYPE
      csvContent: string
    }
  ): Promise<any> => {
    try {
      const { companyId, integrationId, accountingObjectType, csvContent } = payload

      const url = `@api/integrations/v2/accounting/${companyId}/${integrationId}/context/csv/${accountingObjectType}`

      const { data: accountingObjects = [] }: { data: AccountingObject[] } = await Axios.post(url, {
        csv: csvContent
      })

      accountingObjects.forEach((accountingObject) => {
        commit(ACCOUNTING_STORE_MUTATIONS.SET_ACCOUNTING_OBJECT, { companyId, accountingObject })
      })

      return accountingObjects
    } catch (error) {
      throw error
    }
  }
}
