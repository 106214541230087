import { APPROVAL_STATE, APPROVAL_STATE_COLOR } from '@/enums'
import { Decision, DisplayedDecision } from '@/models'
import { useInstanceHelpers, useUser } from '@/composables'

export default function useApprovalProgress() {
  const { t } = useInstanceHelpers()
  const { activeUser } = useUser()

  const setApprovalProgress = (
    approvalDecisions: Decision[],
    approvalState: keyof typeof APPROVAL_STATE,
    userId?: number
  ) => {
    const rounds: { [key: number]: DisplayedDecision[] } = {}

    if (approvalDecisions?.length) {
      approvalDecisions.forEach((decision) => {
        if (decision.approvalRoundNo && !rounds[decision.approvalRoundNo]) {
          rounds[decision.approvalRoundNo] = []
        }

        const roundDecision: DisplayedDecision = {
          id: decision.id,
          text: '',
          state: decision.state,
          color: APPROVAL_STATE_COLOR[decision.state],
          decidedAt: decision.decidedAt,
          reason: decision.reason,
          comment: decision.comment
        }

        const activeUserIsApprover =
          decision.approvingUserId === activeUser.value?.id || (decision.approvingUserId === userId && userId)
          // userId is there for now, while app user is stored in stash

        if (decision.appendedByUserId) {
          const appendedBy = approvalDecisions.find(
            (_decision) => _decision.approvingUserId === decision.appendedByUserId
          )
          if (appendedBy) {
            const text = activeUserIsApprover
              ? `${appendedBy.approvingUserFullname} ${t('youWereAddedAsApprover')}`
              : `${appendedBy.approvingUserFullname} ${t('addedAsApprover')} ${decision.approvingUserFullname}`
            const decisionAppended: DisplayedDecision = {
              text,
              appended: true,
              color: APPROVAL_STATE_COLOR.PREPARED,
              reason: decision.comment
            }
            rounds[decision.approvalRoundNo].push(decisionAppended)
          }
        }

        switch (decision.state) {
          case APPROVAL_STATE.DELEGATED:
            const delegatedTo = approvalDecisions.find((_decision) => _decision.id === decision.delegatedDecisionId)
            if (delegatedTo?.approvingUserId === decision.approvingUserId) {
              // DOCUMENT WAS PREVIOUSLY REJECTED AND SUBMITTED FOR NEW APPROVAL ROUND, LAST REJECT DECISION BECAME DELEGATED
              roundDecision.state = APPROVAL_STATE.REJECTED
              roundDecision.color = APPROVAL_STATE_COLOR[APPROVAL_STATE.REJECTED]
              roundDecision.text = activeUserIsApprover
                ? t('youRejected')
                : `${decision.approvingUserFullname} ${t('didReject')}`
            } else if (delegatedTo?.approvingUserFullname && activeUserIsApprover) {
              roundDecision.text = t('youDelegatedDecision').replace('$delegate', delegatedTo.approvingUserFullname)
            } else if (delegatedTo?.approvingUserFullname) {
              roundDecision.text = t('decisionWasDelegatedTo')
                .replace('$approver', decision.approvingUserFullname)
                .replace('$delegate', delegatedTo.approvingUserFullname)
            }
            break
          case APPROVAL_STATE.APPROVED:
            roundDecision.text = activeUserIsApprover
              ? t('youApproved')
              : `${decision.approvingUserFullname} ${t('didApprove')}`
            break
          case APPROVAL_STATE.REJECTED:
            roundDecision.text = activeUserIsApprover
              ? t('youRejected')
              : `${decision.approvingUserFullname} ${t('didReject')}`
            break
          case APPROVAL_STATE.PENDING:
            roundDecision.text = activeUserIsApprover
              ? t('waitingForYourApproval')
              : `${decision.approvingUserFullname} ${t('isDeciding')}`
            break
          case APPROVAL_STATE.PREPARED:
            if (approvalState === APPROVAL_STATE.REJECTED) {
              roundDecision.text = activeUserIsApprover
                ? t('yourDecisionWasCancelled')
                : `${decision.approvingUserFullname} ${t('decisionWasCancelled')}`
            } else {
              roundDecision.text = activeUserIsApprover
                ? t('yourDecisionPrepared')
                : `${decision.approvingUserFullname} ${t('decisionPrepared')}`
            }
            break
        }

        rounds[decision.approvalRoundNo].push(roundDecision)
      })
    } else if (
      approvalDecisions?.length === 0 &&
      (approvalState === APPROVAL_STATE.APPROVED || approvalState === APPROVAL_STATE.REJECTED)
    ) {
      rounds[1] = [
        {
          state: approvalState,
          color: APPROVAL_STATE_COLOR[approvalState],
          auto: true
        }
      ]
    }

    return rounds
  }

  return {
    setApprovalProgress
  }
}
