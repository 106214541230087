import { validateStatus } from '@/lib/status'
import { TRIP_STATUS, TRIP_STATUS_VALIDATOR, TRIP_STATUS_CATEGORY, STATUS_COLORS } from '@/enums'

export function displayedTripStatus(trip, company) {
  switch (true) {
    case validateStatus(trip.status, TRIP_STATUS_VALIDATOR.BOUNCED): {
      return {
        color: STATUS_COLORS.RED,
        label: 'forwardingFailed',
        status: TRIP_STATUS_CATEGORY.BOUNCED,
        canSubmit: true,
        index: 9
      }
    }

    case validateStatus(trip.status, TRIP_STATUS_VALIDATOR.SENT) &&
      validateStatus(trip.status, TRIP_STATUS_VALIDATOR.APPROVED): {
      return {
        color: STATUS_COLORS.GREEN,
        label: 'approvedAndSent',
        status: TRIP_STATUS_CATEGORY.APPROVED_AND_SENT,
        canSubmit: false,
        index: 1
      }
    }

    case validateStatus(trip.status, TRIP_STATUS_VALIDATOR.SENT): {
      return {
        color: STATUS_COLORS.GREEN,
        label: 'forwardingSuccess',
        status: TRIP_STATUS_CATEGORY.SENT,
        canSubmit: false,
        index: 1
      }
    }

    case validateStatus(trip.status, TRIP_STATUS_VALIDATOR.APPROVING): {
      return {
        color: STATUS_COLORS.BLUE,
        label: 'approving',
        status: TRIP_STATUS_CATEGORY.APPROVING,
        canSubmit: false,
        index: 5
      }
    }

    case validateStatus(trip.status, TRIP_STATUS_VALIDATOR.APPROVED): {
      return {
        color: STATUS_COLORS.GREEN,
        label: 'approved',
        status: TRIP_STATUS_CATEGORY.APPROVED,
        canSubmit: false,
        index: 2
      }
    }

    case validateStatus(trip.status, TRIP_STATUS_VALIDATOR.REJECTED): {
      return {
        color: STATUS_COLORS.RED,
        label: 'rejected',
        status: TRIP_STATUS_CATEGORY.REJECTED,
        canSubmit: true,
        index: 8
      }
    }

    case !validateStatus(trip.status, [TRIP_STATUS.CONFIRMED]): {
      return {
        color: STATUS_COLORS.GREY,
        label: 'draft',
        status: TRIP_STATUS_CATEGORY.DRAFT,
        canSubmit: true,
        index: 7
      }
    }

    case validateStatus(trip.status, TRIP_STATUS_VALIDATOR.PROCESSING): {
      return {
        color: STATUS_COLORS.BLUE,
        label: 'tripProcessing',
        status: TRIP_STATUS_CATEGORY.PROCESSING,
        canSubmit: false,
        index: 3
      }
    }

    case validateStatus(trip.status, [TRIP_STATUS.DIGITIZING]): {
      return {
        color: STATUS_COLORS.BLUE,
        label: 'digitizing',
        status: TRIP_STATUS_CATEGORY.DIGITIZING,
        canSubmit: false,
        index: 4
      }
    }

    case validateStatus(trip.status, TRIP_STATUS_VALIDATOR.CONFIRMED) && company?.settings?.requireReportConfirmation: {
      return {
        color: STATUS_COLORS.BLUE,
        label: 'pendingConfirmation',
        status: TRIP_STATUS_CATEGORY.CONFIRMING,
        canSubmit: false,
        index: 6
      }
    }

    case validateStatus(trip.status, TRIP_STATUS_VALIDATOR.CONFIRMED): {
      return {
        color: STATUS_COLORS.BLUE,
        label: 'submitted',
        status: TRIP_STATUS_CATEGORY.SUBMITTED,
        canSubmit: false,
        index: 6
      }
    }

    default: {
      return {
        color: STATUS_COLORS.GREY,
        label: 'draft',
        status: TRIP_STATUS_CATEGORY.DRAFT,
        canSubmit: true,
        index: 7
      }
    }
  }
}
