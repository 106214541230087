import { computed, watch, ref } from 'vue'
import { CONFIG_STORE_GETTERS, COMPANIES_STORE_ACTIONS, COMPANIES_STORE_GETTERS, STORAGE } from '@/enums'
import { xstore } from '@/store'
import { Company } from '@/models'
import { useContext } from '@/composables'
import _storage from '@/lib/useLocalStorage'

export default function useCompanies() {
  const onCompanyChangeTargetFn = ref((id: number) => {})

  const { router, root, store } = useContext()

  const companyId = computed(() => {
    if (root?.$route.params.companyId) {
      return Number(root?.$route.params.companyId)
    } else {
      return 0
    }
  })

  const firstCompanyId = computed(() => {
    const allCompanies: Company[] = Object.values(companies.value || {})
    if (allCompanies?.length) {
      return allCompanies[0].id
    } else {
      return 0
    }
  })

  const companies = computed(() => {
    return xstore.getters[COMPANIES_STORE_GETTERS.COMPANIES] || {}
  })

  const activeCompany = computed(() => {
    return companies.value?.[companyId.value]
  })

  const activeUserIsAdmin = computed<boolean>(() => {
    return Boolean(activeCompany.value?.companyRoles?.length)
    // for non admin user company roles are not available TODO: maybe there is/should be a better way to tell
  })

  const companyInbox = computed<string>(() => {
    let mailDomain = xstore.getters[CONFIG_STORE_GETTERS.CONFIG].mailDomain
    let alias = activeCompany.value?.settings?.alias || activeCompany.value?.regCode
    return alias && mailDomain ? `${alias}@${mailDomain}` : ''
  })

  const companyUsesReports = computed<Boolean>(() => {
    if (!activeCompany.value) return false
    if (activeCompany.value?.settings?.enableReports !== undefined) {
      return activeCompany.value?.settings?.enableReports
    }
    return true
  })

  const getCompany = async (companyId: any) => {
    if (!companyId) return
    return await store.dispatch(COMPANIES_STORE_ACTIONS.GET_COMPANY, companyId)
  }

  const setActiveCompanyById = (id: number) => {
    _storage[STORAGE.ACTIVE_COMPANY] = id

    const lastSelectedTab = localStorage.getItem('last-selected-tab')
    localStorage.removeItem('last-selected-tab')

    const lastSelectedLayout = localStorage.getItem('last-selected-layout')
    localStorage.removeItem('last-selected-layout')

    const currentTab = root?.$route.params.tab || lastSelectedTab || ''
    const currentLayout = root?.$route.params.layout || lastSelectedLayout || ''
    const viewPath = currentTab && currentLayout ? `/${currentTab}/${currentLayout}` : ''

    const tripId = root?.$route.params.tripId || ''
    const receiptId = root?.$route.params.receiptId || ''
    const receiptPath = receiptId ? `/receipt/${receiptId}` : ''
    const tripPath = tripId ? `/trip/${tripId}` : ''

    router?.push(`/${id}${viewPath}${tripPath}${receiptPath}`)
  }

  const openDefaultView = () => {
    try {
      if ((!companyId.value || !activeCompany.value) && !(Object.values(companies.value).length === 1)) {
        router?.push('/overview/activity')
        return
      }

      if (firstCompanyId.value) {
        setActiveCompanyById(firstCompanyId.value)
      }
    } catch (error) {
      console.log('@openDefaultView', error)
    }
  }

  const onCompanyChange = (targetFn = (id: number) => {}) => {
    onCompanyChangeTargetFn.value = targetFn
  }

  const updateCompanySettings =  async (settings: any) => {
    return xstore.dispatch(COMPANIES_STORE_ACTIONS.UPDATE_SETTINGS, {
      companyId: companyId.value,
      settings
    })
  }

  watch(
    () => companyId.value,
    (id, oldId) => {
      if (id !== undefined && id !== oldId) {
        onCompanyChangeTargetFn.value(id)
      }
    }
  )

  return {
    getCompany,
    setActiveCompanyById,
    openDefaultView,
    onCompanyChange,
    updateCompanySettings,
    activeCompany,
    activeUserIsAdmin,
    companies,
    companyInbox,
    companyUsesReports
  }
}
