import { SORT_DIRECTION, SORT_KEY, STATUS_SEQUENCE, TABLE_NAME, TRANSACTION_WARNING } from '@/enums'
import { receiptStatuses } from '@/lib/statuses/receiptStatuses'
import { useCompanies, useTripStatus, useCostTypes } from '@/composables'
import { ApprovalItem, PaymentTransaction, Receipt, Trip } from '@/models'

export default function useTableSort() {
  const { statusInfo: tripStatus } = useTripStatus()
  const { activeCompany } = useCompanies()
  const { setCostTypeName } = useCostTypes()

  const sortByDate = (aDateString: string, bDateString: string, sortDirection: SORT_DIRECTION) => {
    const dateA = new Date(aDateString).getTime()
    const dateB = new Date(bDateString).getTime()
    if (sortDirection === SORT_DIRECTION.DESCENDING) {
      return dateA - dateB // Sort from oldest to newest
    } else {
      return dateB - dateA // Sort from newest to oldest (default behavior)
    }
  }

  const transactionStatus = (transaction: PaymentTransaction) => {
    if (transaction.warning?.codename) {
      return TRANSACTION_WARNING[transaction.warning.codename as keyof typeof TRANSACTION_WARNING]
    } else if (transaction.receiptId) {
      return 'AAAAAA' // to differentiate matched and unmatched docs
    } else {
      return 'ZZZZZZ' // to differentiate matched and unmatched docs
    }
  }

  const receiptStatus = (receipt: Receipt) => {
    const statuses = receiptStatuses(receipt, activeCompany.value)
    return statuses[1]?.index || statuses[0]?.index || STATUS_SEQUENCE.NONE
  }

  const sortReceiptsTable = (sortableArray: Receipt[], sortKey: SORT_KEY, sortDirection: SORT_DIRECTION) => {
    const sorted = [...sortableArray]

    if (!sortKey) {
      return sorted
    }

    sorted.sort((a: { [key: string]: any }, b: { [key: string]: any }) => {
      let first: string | number = ''
      let second: string | number = ''

      if (sortKey === SORT_KEY.STATUS) {
        first = receiptStatus(a as Receipt)
        second = receiptStatus(b as Receipt)
      } else if (sortKey === SORT_KEY.EXPENSE_TYPE) {
        first = setCostTypeName(a as Receipt, localStorage.language) || ''
        second = setCostTypeName(b as Receipt, localStorage.language) || ''
      } else if (sortKey === SORT_KEY.SUBMITTER) {
        first = a.submitter?.fullName || a.submitter?.email || ''
        second = b.submitter?.fullName || b.submitter?.email || ''
      } else if (sortKey === SORT_KEY.SUM) {
        first = Number(a.sum) || 0
        second = Number(b.sum) || 0
      } else {
        first = a[sortKey] ?? ''
        second = b[sortKey] ?? ''
      }

      if (first === second) {
        return sortByDate(a.createdAt, b.createdAt, sortDirection)
      }

      if (typeof first === 'string' && typeof second === 'string') {
        return first.localeCompare(second)
      } else if (typeof first === 'number' && typeof second === 'number') {
        return first - second
      }

      return -1
    })

    if (sortDirection === SORT_DIRECTION.DESCENDING) {
      sorted.reverse()
    }

    return sorted
  }

  const sortTripsTable = (sortableArray: Trip[], sortKey: SORT_KEY, sortDirection: SORT_DIRECTION) => {
    const sorted = [...sortableArray]

    if (!sortKey) {
      return sorted
    }

    sorted.sort((a, b) => {
      let first: string | number = ''
      let second: string | number = ''

      if (sortKey === SORT_KEY.SUBMITTER) {
        first = a.submitter?.fullName || a.submitter?.email || ''
        second = b.submitter?.fullName || b.submitter?.email || ''
      } else if (sortKey === SORT_KEY.STATUS) {
        first = tripStatus(a, activeCompany.value).index
        second = tripStatus(b, activeCompany.value).index
      } else if (sortKey === SORT_KEY.DATE) {
        first = a.startDate || ''
        second = b.startDate || ''
      } else {
        first = a[sortKey] ?? ''
        second = b[sortKey] ?? ''
      }

      if (first === second) {
        return sortByDate(a.createdAt, b.createdAt, sortDirection)
      }

      if (typeof first === 'string' && typeof second === 'string') {
        return first.localeCompare(second)
      } else if (typeof first === 'number' && typeof second === 'number') {
        return first - second
      }

      return -1
    })

    if (sortDirection === SORT_DIRECTION.DESCENDING) {
      sorted.reverse()
    }

    return sorted
  }

  const sortApprovalsTable = (sortableArray: ApprovalItem[], sortKey: SORT_KEY, sortDirection: SORT_DIRECTION) => {
    const sorted = [...sortableArray]

    if (!sortKey) {
      return sorted
    }

    sorted.sort((a: { [key: string]: any }, b: { [key: string]: any }) => {
      let first: string | number = ''
      let second: string | number = ''

      if (sortKey === SORT_KEY.SUBMITTER) {
        first = a.data?.trip?.submitter?.fullName || a.data?.receipt?.submitter?.fullName || ''
        second = b.data?.trip?.submitter?.fullName || b.data?.receipt?.submitter?.fullName || ''
      } else if (sortKey === SORT_KEY.DOCUMENT_TYPE) {
        first = a.data?.trip?.subtype || a.data?.trip?.type || ''
        second = b.data?.trip?.subtype || b.data?.trip?.type || ''
      } else if (sortKey === SORT_KEY.DOCUMENT) {
        first = a.data?.trip?.name || a.data?.receipt?.supplier || ''
        second = b.data?.trip?.name || b.data?.receipt?.supplier || ''
      } else if (sortKey === SORT_KEY.SUM) {
        first = Number(a.data?.receipt?.sum) || 0
        second = Number(b.data?.receipt?.sum) || 0
      } else if (sortKey === SORT_KEY.DATE) {
        first = a.data?.trip?.startDate || a.data?.receipt?.issued || ''
        second = b.data?.trip?.startDate || b.data?.receipt?.issued || ''
      } else {
        first = a[sortKey] ?? ''
        second = b[sortKey] ?? ''
      }

      if (first === second) {
        return sortByDate(a.ts, b.ts, sortDirection)
      }

      if (typeof first === 'string' && typeof second === 'string') {
        return first.localeCompare(second)
      } else if (typeof first === 'number' && typeof second === 'number') {
        return first - second
      }

      return -1
    })

    if (sortDirection === SORT_DIRECTION.DESCENDING) {
      sorted.reverse()
    }

    return sorted
  }

  const sortTransactionsTable = (
    sortableArray: PaymentTransaction[],
    sortKey: SORT_KEY,
    sortDirection: SORT_DIRECTION
  ) => {
    const sorted = [...sortableArray]

    if (!sortKey) {
      return sorted
    }

    sorted.sort((a: { [key: string]: any }, b: { [key: string]: any }) => {
      let first: string | number = ''
      let second: string | number = ''

      if (sortKey === SORT_KEY.NAME) {
        first = typeof a.trip?.name === 'string' ? a.trip?.name : ''
        second = typeof b.trip?.name === 'string' ? b.trip?.name : ''
      } else if (sortKey === SORT_KEY.STATUS) {
        first = transactionStatus(a as PaymentTransaction)
        second = transactionStatus(b as PaymentTransaction)
      } else if (sortKey === SORT_KEY.CARDLFD) {
        first = Number(a.paymentMethod?.cardLfd) || 0
        second = Number(b.paymentMethod?.cardLfd) || 0
      } else if (sortKey === SORT_KEY.SUM) {
        first = Number(a.sum) || 0
        second = Number(b.sum) || 0
      } else {
        first = a[sortKey] ?? ''
        second = b[sortKey] ?? ''
      }

      if (first === second) {
        return sortByDate(a.date, b.date, sortDirection)
      }

      if (typeof first === 'string' && typeof second === 'string') {
        return first.localeCompare(second)
      } else if (typeof first === 'number' && typeof second === 'number') {
        return first - second
      }

      return -1
    })

    if (sortDirection === SORT_DIRECTION.DESCENDING) {
      sorted.reverse()
    }

    return sorted
  }

  const setSortToLocalstorage = (tableName: TABLE_NAME, key: SORT_KEY, direction: SORT_DIRECTION) => {
    localStorage.setItem(tableName + 'SortKey', key)
    localStorage.setItem(tableName + 'SortDirection', direction)
  }

  const getSortFromLocalstorage = (tableName: TABLE_NAME) => {
    const localSortKey = localStorage?.getItem(tableName + 'SortKey') || ''
    const localSortDirection = localStorage?.getItem(tableName + 'SortDirection') || ''
    return { key: localSortKey, direction: localSortDirection }
  }

  return {
    sortReceiptsTable,
    sortTransactionsTable,
    sortTripsTable,
    sortApprovalsTable,
    setSortToLocalstorage,
    getSortFromLocalstorage
  }
}
