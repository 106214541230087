import { cloneDeep } from 'lodash-es'
import { TRIPS_STORE_MUTATIONS, TRIPS_STORE_STATE } from '@/enums'
import { TripsStoreState, Trip, DailyAllowanceItem, PaymentTransaction, BankFormat } from '@/models'

export default {
  [TRIPS_STORE_MUTATIONS.SET_TRIPS]: (state: TripsStoreState, trips: Trip[]) => {
    for (const trip of trips) {
      if (state[TRIPS_STORE_STATE.TRIPS][trip.id]) {
        state[TRIPS_STORE_STATE.TRIPS][trip.id] = {
          ...state[TRIPS_STORE_STATE.TRIPS][trip.id],
          ...trip
        }
      } else {
        state[TRIPS_STORE_STATE.TRIPS][trip.id] = trip
      }
    }
  },
  [TRIPS_STORE_MUTATIONS.SET_TRIP_VALUES]: (state: TripsStoreState, payload: any = {}) => {
    const { trip, values } = payload
    const { id } = trip

    state[TRIPS_STORE_STATE.TRIPS][id] = {
      ...trip,
      ...values
    }
  },
  [TRIPS_STORE_MUTATIONS.REMOVE_TRIP]: (state: TripsStoreState, id: number) => {
    if (state[TRIPS_STORE_STATE.TRIPS][id]) {
      delete state[TRIPS_STORE_STATE.TRIPS][id]
    }
  },
  [TRIPS_STORE_MUTATIONS.SET_DAILY_ALLOWANCE]: (state: any, payload: any = {}) => {
    const { tripId, allowance } = payload
    state[TRIPS_STORE_STATE.ALLOWANCE][Number(tripId)] = allowance
  },
  [TRIPS_STORE_MUTATIONS.SET_DAILY_ALLOWANCE_ITEM]: (state: any, payload: any = {}) => {
    const { tripId, allowanceEntry } = payload
    if (state[TRIPS_STORE_STATE.ALLOWANCE][Number(tripId)]) {
      const entryIndex = state[TRIPS_STORE_STATE.ALLOWANCE][Number(tripId)].findIndex(
        (item: DailyAllowanceItem) => item.id === allowanceEntry.id
      )
      // existing daily allowance entry needs to be replaced
      if (entryIndex > -1) {
        state[TRIPS_STORE_STATE.ALLOWANCE][Number(tripId)][entryIndex] = allowanceEntry
      }
      // add new one
      else {
        state[TRIPS_STORE_STATE.ALLOWANCE][Number(tripId)].push(allowanceEntry)
        // WHAT IF ALLOWANCE ENTRY IS ADDED IN THE MIDDLE (BY DATE)???
      }
    }
  },
  [TRIPS_STORE_MUTATIONS.REMOVE_DAILY_ALLOWANCE_ITEM]: (state: any, payload: any = {}) => {
    const { tripId, allowanceEntryId } = payload
    state[TRIPS_STORE_STATE.ALLOWANCE][Number(tripId)] = state[TRIPS_STORE_STATE.ALLOWANCE][Number(tripId)].filter(
      (item: DailyAllowanceItem) => item.id !== allowanceEntryId
    )
  },
  [TRIPS_STORE_MUTATIONS.SET_STATEMENT]: (state: any, payload: any = {}) => {
    const { tripId, statement } = payload
    state[TRIPS_STORE_STATE.STATEMENT][Number(tripId)] = statement
  },
  [TRIPS_STORE_MUTATIONS.SET_STATEMENT_TRANSACTION]: (state: any, payload: any = {}) => {
    const { tripId, transaction } = payload
    if (state[TRIPS_STORE_STATE.STATEMENT][tripId]?.transactions) {
      const tripTransactions = cloneDeep(state[TRIPS_STORE_STATE.STATEMENT][tripId]?.transactions)
      const entryIndex = tripTransactions.findIndex((item: PaymentTransaction) => item.id === transaction.id)
      if (entryIndex > -1) {
        tripTransactions[entryIndex] = transaction
      } else {
        tripTransactions.push(transaction)
      }

      state[TRIPS_STORE_STATE.STATEMENT][tripId] = {
        ...state[TRIPS_STORE_STATE.STATEMENT][tripId],
        transactions: tripTransactions
      }
    }
  },
  [TRIPS_STORE_MUTATIONS.SET_STATEMENT_FORMATS]: (state: any, supportedFormats: BankFormat[] = []) => {
    state[TRIPS_STORE_STATE.STATEMENT_FORMATS] = supportedFormats
  }
}
